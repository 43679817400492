import React, { useEffect, useState } from "react";
import { Alert, Col, Container, Form, Row } from "react-bootstrap";
import "./Checkout.css";
import { useNavigate } from "react-router-dom";
import LOGO from "./wincenzo_logo.png";
import { useDispatch, useSelector } from "react-redux";
import { deleteCartAllItem, getCart } from "../../features/cart/cartSlice";
import { useFormik } from "formik";
import { addOrders, allOrders, sendOrderEmailThunk, fetchToken  } from "../../features/auth/authSlice";
import axios from "axios";
import { baseUrl } from "../../configs/baseUrl";
import { prod_URL } from "../../configs/prodUrl";




import { countries, states } from "./options";
import { getCoupons } from "../../features/product/productSlice";
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  shippingAddress: Yup.object().shape({
    name: Yup.string().required('Name is required'),
    address: Yup.string().required('Address is required'),
    city: Yup.string().required('City is required'),
    state: Yup.string().required('State is required'),
    zipCode: Yup.number()
      .typeError('ZIP Code must be a number')
      .positive('ZIP Code must be a positive number')
      .integer('ZIP Code must be an integer')
      .test('len', 'ZIP Code must be exactly 6 digits', val => val && val.toString().length === 6)
      .required('ZIP Code is required'),
  }),
  paymentMethod: Yup.string().required('Payment method is required'),
});

export default function Checkout() {
  const { cart, cartTotal } = useSelector((state) => state?.cart);
  const { coupons } = useSelector((state) => state?.product);
  const { user } = useSelector((state) => state?.auth);
  const { isLoading, successMessage, error } = useSelector((state) => state.auth);
  // const { settings } = useSelector((state) => state?.settings);
  const imgUrl = process.env.REACT_APP_BASE_URL_IMAGE;
  let deliveryAddress = user?.shippingAddresses;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [amount, setAmount] = useState(cartTotal);
  const [isvalidCoupon, setIsValidCoupon] = useState(false);
  const [mycoupon, setMyCoupon] = useState("");
  const [showEmptyCartAlert, setShowEmptyCartAlert] = useState(false); // New state for empty cart alert

  useEffect(() => {
    setAmount(cartTotal);
  }, [cartTotal]);

  useEffect(() => {
    dispatch(getCoupons());
  }, [dispatch, cart]);

  useEffect(() => {
    if (user?._id) dispatch(getCart(user?._id));
  }, [dispatch, user._id]);

  const handleSendEmail = async (orderDetails) => {
    try {
      await dispatch(sendOrderEmailThunk(orderDetails));
      await dispatch(fetchToken());
    } catch (err) {
      console.error('Failed to send email:', err);
    }
  };



  const applyCoupon = () => {
    const { couponCode } = formik.values;

    if (!couponCode) {
      setAlertMessage("Please enter a coupon code.");
      setShowAlert(true);
      return;
    }

    const coupon = coupons.find((coupon) => coupon.code === couponCode);

    if (!coupon) {
      setAlertMessage("Invalid coupon code. Please enter a valid code.");
      setShowAlert(true);
      return;
    }

    const currentDate = new Date();
    const startDate = new Date(coupon.startDate);
    const endDate = new Date(coupon.expirationDate);

    if (currentDate < startDate || currentDate > endDate) {
      setAlertMessage("Coupon is expired.");
      setShowAlert(true);
      return;
    }

    const applicableProducts = cart.filter((item) =>
      coupon.products?.includes(item.productId?._id)
    );
    if (applicableProducts.length === 0) {
      setAlertMessage("No products in the cart are applicable for this coupon");
      setShowAlert(true);
      return;
    }
    setIsValidCoupon(true);
    setMyCoupon(coupon);
    // Calculate discount
    const discountAmount = (coupon.percentage / 100) * cartTotal;
    const totalAfterDiscount = cartTotal - discountAmount;
    setAmount(totalAfterDiscount);
  };

  const products = [];
  cart?.forEach((e) => {
    products?.push({
      product: e?.productId?._id,
      quantity: e?.quantity,
    });
  });

  const getAvailablePaymentMethods = () => {
    const paymentMethods = new Set();
    cart.forEach((item) => {
      if (!paymentMethods.has(item.productId.paymentMethod)) {
        paymentMethods.add(item.productId.paymentMethod);
      }
    });
    return Array.from(paymentMethods);
  };

  const [availablePaymentMethods, setAvailablePaymentMethods] = useState([]);

  useEffect(() => {
    setAvailablePaymentMethods(getAvailablePaymentMethods());
  }, [cart]);

  // const checkoutHandler = async (e, amount) => {
  //   e.preventDefault();

  //   if (cart.length === 0) { // Check if cart is empty
  //     setAlertMessage("Your cart is empty. Please add items to your cart before proceeding.");
  //     setShowEmptyCartAlert(true);
  //     return;

  //   }

  //   if (formik.values.paymentMethod === "Online Payment") {
  //     const { data: { key } } = await axios.get(`${baseUrl}payment/keyid`);
  //     const { data: { order } } = await axios.post(`${baseUrl}payment/checkout`, { amount });

  //     const options = {
  //       key,
  //       amount: order.amount,
  //       currency: "INR",
  //       name: "Wincenzo",
  //       description: "Wincenzo Online Payment",
  //       image: LOGO,
  //       order_id: order.id,
  //       callback_url: `${baseUrl}payment/paymentverification`,
  //       prefill: {
  //         name: user?.username,
  //         email: user?.email,
  //         contact: user?.phoneNumber,
  //       },
  //       notes: {
  //         "address": "razorapy official"
  //       },
  //       theme: {
  //         "color": "#F99A00"
  //       }
  //     };
  //     const razor = new window.Razorpay(options);
  //     razor.open();
  //     formik.handleSubmit(amount);
  //   } else {
  //     formik.handleSubmit(amount);
  //   }

  




  // const checkoutHandler = async (e, amount) => {
  //   e.preventDefault();
  
  //   // Check if the cart is empty
  //   if (cart.length === 0) {
  //     setAlertMessage("Your cart is empty. Please add items to your cart before proceeding.");
  //     setShowEmptyCartAlert(true);
  //     return;
  //   }
  
  //   // Handle payment based on the selected payment method
  //   if (formik.values.paymentMethod === "Online Payment") {
  //     const data = {
  //       name: user?.name || "John Doe",  // Fetch user's name or default
  //       mobileNumber: user?.mobileNumber || 1234567890,  // Fetch mobile number
  //       amount: amount,
  //     };
  
  //     try {
  //       const response = await axios.post(`${baseUrl}orders/order`, data);
  
  //       // Redirect the user to the payment gateway
  //       window.location.href = response.data.url;
  
  //       // Listen for payment success, then create the order
  //       window.addEventListener("paymentSuccess", async () => {
  //         const orderData = {
  //           products: cart.map(item => item._id),
  //           userId: user?._id,
  //           total: amount,
  //           discount: mycoupon?.percentage || 0,
  //           couponCode: mycoupon?.code || "xxxx",
  //           shippingAddress: formik.values.shippingAddress,
  //           paymentMethod: formik.values.paymentMethod,
  //           orderTotal: cartTotal,
  //         };
  
  //         // Dispatch the order creation
  //         await dispatch(addOrders(orderData));
  
  //         // Clear the cart after successful order creation
  //         const productIds = cart.map(item => item._id);
  //         await dispatch(deleteCartAllItem(productIds));
  
  //         // Fetch the updated orders
  //         if (user?._id) {
  //           await dispatch(allOrders(user?._id));
  //         }
  
  //         formik.resetForm();
  //         setIsValidCoupon(false);
  //         setMyCoupon("");
  //       });
  //     } catch (error) {
  //       console.log("Error in payment", error);
  //     }
  //   } else {
  //     // Handle non-online payment submission
  //     formik.handleSubmit();
  //   }
  // };
  




  // const checkoutHandler = async (e, amount) => {
  //   e.preventDefault();

  //   // Check if the cart is empty
  //   if (cart.length === 0) {
  //       setAlertMessage("Your cart is empty. Please add items to your cart before proceeding.");
  //       setShowEmptyCartAlert(true);
  //       return;
  //   }

  //   // Handle payment based on the selected payment method
  //   if (formik.values.paymentMethod === "Online Payment") {
  //       const data = {
  //           name: "John Doe",
  //           mobileNumber: 1234567890,
  //           amount: amount,
  //       };

  //       try {
  //           // Initiate payment request
  //           const response = await axios.post(`${baseUrl}orders/order`, data);
  //           console.log(response.data);

  //           // Redirect to PhonePe payment URL
  //           window.location.href = response.data.url;

  //           // Once redirected back, check payment status
  //           const paymentStatus = await axios.get(`${baseUrl}/orders/checkPhonePePaymentStatus?id=${response.data.merchantTransactionId}&userId=${user?._id}&total=${amount}&products=${JSON.stringify(cart)}`);
            
  //           if (paymentStatus.data.success) {
  //               console.log('Payment was successful!');
  //               // Call addOrder action here
  //               dispatch(addOrders({
  //                   userId: user?._id,
  //                   products: cart,
  //                   total: amount,
  //                   discount: mycoupon?.percentage || 0,
  //                   couponCode: mycoupon?.code || "xxxx",
  //                   shippingAddress: formik.values.shippingAddress,
  //                   paymentMethod: formik.values.paymentMethod,
  //                   orderTotal: cartTotal
  //               }));

  //               // Optional: Clear cart after order is placed
  //               await dispatch(deleteCartAllItem(cart.map(item => item._id)));

  //               // Handle email notifications if needed
  //               const orderDetails = { /* Include order details */ };
  //               await handleSendEmail(orderDetails);
  //           } else {
  //               console.log('Payment failed:', paymentStatus.data);
  //               setAlertMessage("Payment failed. Please try again.");
  //               // setShowPaymentFailureAlert(true);
  //           }
  //       } catch (error) {
  //           console.log("Error in payment or order creation", error);
  //           setAlertMessage("There was an error processing your payment.");
  //           // setShowPaymentFailureAlert(true);
  //       }
  //   } else {
  //       // Handle non-online payment submission
  //       formik.handleSubmit(amount);
  //       await handleSendEmail({ /* Non-online payment order details */ });
  //   }
  // };






//live code //////////////////////////////////////////////////
  const checkoutHandler = async (e, amount) => {
    e.preventDefault();

    // Check if the cart is empty
    if (cart.length === 0) {
      setAlertMessage(
        "Your cart is empty. Please add items to your cart before proceeding."
      );
      setShowEmptyCartAlert(true);
      return;
    }

    // Handle payment based on the selected payment method
    if (formik.values.paymentMethod === "Online Payment") {

      const orderData = {
        products: products, 
        userId: user?._id,
        total: amount,
        orderTotal: cartTotal,
        discount: isvalidCoupon ? mycoupon.percentage : 0,
        couponCode: isvalidCoupon ? mycoupon.code : null,
        shippingAddress: formik.values.shippingAddress,
        paymentMethod: formik.values.paymentMethod,
      };

      console.log("Order Data being sent:", JSON.stringify(orderData, null, 2)); // Log order data
      

      try {
        const response = await axios.post(`${baseUrl}orders/order`, orderData);
        console.log('response log',response.data);
        
  // window.PhonePeCheckout.transact({ tokenUrl:response.data.url });
        window.location.href = response.data.url;
//window.PhonePeCheckout.transact({ tokenUrl :response.data.url, callback, type: "IFRAME" });
   
   

// function callback (response) {
  
//   if (response === 'USER_CANCEL') {
    
//     return;
//   } else if (response === 'CONCLUDED') {
 
//     return;
//   }
      } catch (error) {
        console.log("error in payment", error);
      }
    } else {
      // Handle non-online payment submission
      formik.handleSubmit(amount);
    }
    const orderDetails = {
      /* ... */
    };
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      products: products,
      userId: user?._id,
      total: amount,
      discount: "",
      couponCode: "",
      shippingAddress: {
        label: "",
        address: "",
        zipCode: "",
        country: "IN", // Default to India
        state: "",
        city: "",
      },
      paymentMethod: "",
      orderTotal: cartTotal,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      let discount = "";
      let couponCode = "";

      if (isvalidCoupon && mycoupon) {
        discount = mycoupon.percentage;
        couponCode = mycoupon.code;
      }
      const data = {
        products: values.products,
        userId: values.userId,
        total: values.total,
        discount: discount || 0,
        couponCode: couponCode || "xxxx",
        shippingAddress: values.shippingAddress,
        paymentMethod: values.paymentMethod,
        orderTotal: values.orderTotal,
      };

      dispatch(addOrders(data));
      setIsValidCoupon(false);
      setMyCoupon("");

      setTimeout(async () => {
        try {
          if (user?._id) {
            await dispatch(allOrders(user?._id));
          }

          const productIds = cart.map((item) => item._id);
          await dispatch(deleteCartAllItem(productIds));
          await handleSendEmail();


          formik.resetForm();
        } catch (error) {
          console.error("Error deleting cart items or fetching orders:", error);
        }
      }, 1000);
    },
  });












// // palak code ----------------------------------------------------------------------------------
// // latest code
//   const checkoutHandler = async (e, amount) => {
//     e.preventDefault();

//     // Check if the cart is empty
//     if (cart.length === 0) {
//         setAlertMessage("Your cart is empty. Please add items to your cart before proceeding.");
//         setShowEmptyCartAlert(true);
//         return;
//     }

//     // Handle payment based on the selected payment method
//     if (formik.values.paymentMethod === "Online Payment") {
//       const data = {
//         name: "John Doe",
//         mobileNumber:1234567890,
//        // amount:100,
//         amount: amount
//       }
//       try {
//         const response = await axios.post(`${baseUrl}orders/order`, data)
//         console.log(response.data)
//         window.location.href = response.data.url
//       } catch (error) {
//         console.log("error in payment", error)
//       }
//     } else {
//         // Handle non-online payment submission
//         formik.handleSubmit(amount);
//     }
//     const orderDetails = { /* ... */ };
//     await handleSendEmail(orderDetails);
// };

  
//   // const checkoutHandler = async (e, amount) => {
//   //   e.preventDefault();
  
//   //   // Check if the cart is empty
//   //   if (cart.length === 0) {
//   //     setAlertMessage("Your cart is empty. Please add items to your cart before proceeding.");
//   //     setShowEmptyCartAlert(true);
//   //     return;
//   //   }
  
//   //   // Handle payment based on the selected payment method
//   //   if (formik.values.paymentMethod === "Online Payment") {
//   //     try {
//   //       // Fetch the payment key from the server
//   //       const { data: { key } } = await axios.get(`${baseUrl}payment/keyid`);
        
//   //       // Create a new order
//   //       const { data: { order } } = await axios.post(`${baseUrl}payment/checkout`, { amount });
        
//   //       // Prepare options for Razorpay
//   //       const options = {
//   //         key,
//   //         amount: order.amount,
//   //         currency: "INR",
//   //         name: "Wincenzo",
//   //         description: "Wincenzo Online Payment",
//   //         image: LOGO,
//   //         order_id: order.id,
//   //         callback_url: `${baseUrl}payment/paymentverification`,
//   //         prefill: {
//   //           name: user?.username,
//   //           email: user?.email,
//   //           contact: user?.phoneNumber,
//   //         },
//   //         notes: {
//   //           "address": "razorapy official"
//   //         },
//   //         theme: {
//   //           "color": "#F99A00"
//   //         }
//   //       };
  
//   //       // Initialize and open Razorpay
//   //       const razor = new window.Razorpay(options);
//   //       razor.open();
  
//   //       // Optionally handle form submission (if needed)
//   //       // formik.handleSubmit(amount);
//   //     } catch (error) {
//   //       console.error('Error during checkout:', error);
//   //       setAlertMessage('An error occurred while processing your payment. Please try again.');
//   //       setShowEmptyCartAlert(true); // Adjust as needed
//   //     }
//   //   } else {
//   //     // Handle non-online payment submission
//   //     formik.handleSubmit(amount);
//   //   }
//   //   const orderDetails = { /* ... */ };

//   //   await handleSendEmail(orderDetails);
//   // };

//   const formik = useFormik({
//     enableReinitialize: true,
//     initialValues: {
//       products: products,
//       userId: user?._id,
//       total: amount,
//       discount: "",
//       couponCode: "",
//       shippingAddress: {
//         label: "",
//         address: "",
//         zipCode: "",
//         country: "IN",  // Default to India
//         state: "",
//         city: "",
//       },
//       paymentMethod: "",
//       orderTotal: cartTotal
//     },
//     validationSchema: validationSchema,
//     onSubmit: (e, amount) => {
//       let discount = "";
//       let couponCode = "";

//       if (isvalidCoupon && mycoupon) {
//         discount = mycoupon.percentage;
//         couponCode = mycoupon.code;
//       }
//       const data = {
//         products: e?.products,
//         userId: e?.userId,
//         total: e?.total,
//         discount: discount || 0,
//         couponCode: couponCode || "xxxx",
//         shippingAddress: e?.shippingAddress,
//         paymentMethod: e?.paymentMethod,
//         orderTotal: e?.orderTotal,
//       };

     
//       dispatch(addOrders(data));
//       setIsValidCoupon(false);
//       setMyCoupon("");
      
//       setTimeout(async () => {
//         try {
//           if (user?._id) {
//             await dispatch(allOrders(user?._id));
//           }

//           const productIds = cart.map(item => item._id);

//           await dispatch(deleteCartAllItem(productIds));

//           formik.resetForm();
//         } catch (error) {
//           console.error("Error deleting cart items or fetching orders:", error);
//         }
//       }, 900);
      
//     },
//   });
//   // palak code end---------------------------------------------------------------------------
 
  return (
    <>
      <div className="checkout-section">
      {showEmptyCartAlert && <Alert variant="danger">{alertMessage}</Alert>} {/* Show alert for empty cart */}
        <Container>
          <form className="checkout-compo">
            <AddressDetail
              formik={formik}
              deliveryAddress={deliveryAddress}
              availablePaymentMethods={availablePaymentMethods}
              checkoutHandler={checkoutHandler}
              amount={amount}
            />
            <OrderSummary
              cart={cart}
              imgUrl={imgUrl}
              formik={formik}
              cartTotal={cartTotal}
              applyCoupon={applyCoupon}
              showAlert={showAlert}
              alertMessage={alertMessage}
              isvalidCoupon={isvalidCoupon}
              mycoupon={mycoupon}
              amount={amount}
            />
          </form>
        </Container>
      </div>
    </>
  );
};


function AddressDetail({ formik, deliveryAddress = [], availablePaymentMethods, checkoutHandler, amount }) {
  return (
    <Col lg={7} md={6} className="address-detail">
      <div className="delivery-add">
        <div className="mb-3">
          <h5 className="m-0 mb-3">Select Address</h5>
          {deliveryAddress && deliveryAddress.length > 0 ? 
            <div className="d-flex flex-column ">
              {deliveryAddress.map((item, index) => (
                <div key={index} className="mb-3 border border-1 rounded overflow-hidden">
                  <div className="bg-dark-subtle d-flex align-items-center border-bottom px-2 py-1 ">
                    <Form.Check
                      type="radio"
                      id={`address${index}`}
                      name="selectedAddress"
                      value={item.address}
                      checked={formik.values.shippingAddress.address === item.address}
                      onChange={() => formik.setFieldValue("shippingAddress", item)}
                      className="me-3"
                    />
                    <Form.Group>
                      <Form.Label className="m-0" htmlFor={`address${index}`}>
                        {`Address ${index + 1}`}
                      </Form.Label>
                    </Form.Group>
                  </div>
                  <address className="m-0 px-2 py-1">
                    {item.address}, {item.city}, {item.state}, {item.country}, {item.zipCode}
                  </address>
                </div>
              ))}
            </div>
            :
            <Alert variant="danger">Add a new address for the order below</Alert>
          }
        </div>
        <div className="mb-3">
          <h5 className="m-0 mb-3">Delivery Address</h5>
          <div className="country-input mb-3">
            <Form.Select
              aria-label="Default select example"
              onChange={formik.handleChange("shippingAddress.country")}
              value={formik.values.shippingAddress.country}
            >
              <option value="IN">India</option>
              {countries.map(country => (
                <option key={country.value} value={country.value}>{country.label}</option>
              ))}
            </Form.Select>
            {formik.errors.shippingAddress?.country && <small className="text-danger">{formik.errors.shippingAddress.country}</small>}
          </div>
          <div className="name-detail mb-3 ">
            <Row>
              <Col lg={12} md={12} className="mb-3">
                <Form.Control
                  type="text"
                  placeholder="Name"
                  onChange={formik.handleChange("shippingAddress.name")}
                  value={formik.values.shippingAddress.name}
                />
                {formik.errors.shippingAddress?.name && <small className="text-danger">{formik.errors.shippingAddress.name}</small>}
              </Col>
            </Row>
          </div>
          <div className="addr-detail mb-3">
            <Form.Control type="text"
              placeholder="Address"
              onChange={formik.handleChange("shippingAddress.address")}
              value={formik.values.shippingAddress.address}
            />
            {formik.errors.shippingAddress?.address && <small className="text-danger">{formik.errors.shippingAddress.address}</small>}
          </div>
          <div className="pin-detail d-flex mb-3">
            <Row className="flex-wrap">
              <Col lg={4} md={6}>
                <Form.Control
                  className="mb-3 me-3 city"
                  type="text"
                  placeholder="City"
                  onChange={formik.handleChange("shippingAddress.city")}
                  value={formik.values.shippingAddress.city}
                />
                {formik.errors.shippingAddress?.city && <small className="text-danger">{formik.errors.shippingAddress.city}</small>}
              </Col>
              <Col lg={4} md={6}>
                <Form.Select
                  aria-label="Default select example"
                  className="mb-3 me-3"
                  onChange={formik.handleChange("shippingAddress.state")}
                  value={formik.values.shippingAddress.state}
                >
                  {states.map(state => (
                    <option key={state.value} value={state.value}>{state.label}</option>
                  ))}
                </Form.Select>
                {formik.errors.shippingAddress?.state && <small className="text-danger">{formik.errors.shippingAddress.state}</small>}
              </Col>
              <Col lg={4} md={6}>
                <Form.Control
                  className="mb-3 pincode"
                  type="text"
                  placeholder="PIN code"
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d*$/.test(value) && value.length <= 6) {
                      formik.setFieldValue("shippingAddress.zipCode", value);
                    }
                  }}
                  value={formik.values.shippingAddress.zipCode}
                />
                {formik.errors.shippingAddress?.zipCode && <small className="text-danger">{formik.errors.shippingAddress.zipCode}</small>}
              </Col>
            </Row>
          </div>
          <div className="payment mb-4">
            <h5 className="m-0 mb-1">Payment</h5>
            <p className="m-0 mb-3">All transactions are secure and encrypted.</p>
            <div className="d-flex flex-column gap-2">
              {availablePaymentMethods.includes("BOTH") && (
                <>
                  <div className="d-flex gap-2">
                    <input
                      type="radio"
                      id="Online Payment"
                      name="paymentMethod"
                      value="Online Payment"
                      checked={formik.values.paymentMethod === "Online Payment"}
                      onChange={formik.handleChange}
                    />
                    <label className="form-check-label" htmlFor="Online Payment">
                      Online Payment
                    </label>
                  </div>
                  <div className="d-flex gap-2">
                    <input
                      type="radio"
                      id="Cash on Delivery"
                      name="paymentMethod"
                      value="Cash on Delivery"
                      checked={formik.values.paymentMethod === "Cash on Delivery"}
                      onChange={formik.handleChange}
                    />
                    <label className="form-check-label" htmlFor="Cash on Delivery">
                      Cash on Delivery
                    </label>
                  </div>
                </>
              )}
              {availablePaymentMethods.includes("ONLINE") && (
                <div className="d-flex gap-2">
                  <input
                    type="radio"
                    id="Online Payment"
                    name="paymentMethod"
                    value="Online Payment"
                    checked={formik.values.paymentMethod === "Online Payment"}
                    onChange={formik.handleChange}
                  />
                  <label className="form-check-label" htmlFor="Online Payment">
                    Online Payment
                  </label>
                </div>
              )}
              {availablePaymentMethods.includes("COD") && (
                <div className="d-flex gap-2">
                  <input
                    type="radio"
                    id="Cash on Delivery"
                    name="paymentMethod"
                    value="Cash on Delivery"
                    checked={formik.values.paymentMethod === "Cash on Delivery"}
                    onChange={formik.handleChange}
                  />
                  <label className="form-check-label" htmlFor="Cash on Delivery">
                    Cash on Delivery
                  </label>
                </div>
              )}
            </div>
            {formik.errors.paymentMethod && <small className="text-danger">{formik.errors.paymentMethod}</small>}
          </div>
          <div className="complete-btn">
            <button
              className="comp-order-btn"
              type="button"
              onClick={(e) => checkoutHandler(e, amount)}
            >
              Complete Order
            </button>
          </div>
        </div>
      </div>
    </Col>
  );
}

function OrderSummary({ cart, imgUrl, formik, cartTotal, applyCoupon, showAlert, alertMessage, isvalidCoupon, mycoupon, amount }) {
  return (
    <Col lg={5} md={6} sm={12} className="cart-detail">
      <div className="order-detail">
        <h5 className="m-0 mb-3">Order Summary</h5>
        {cart.length > 0 ? (
          <>
            <div className="m-3">
              {cart?.map((el) => (
                <div
                  className="product-detail d-flex align-items-center justify-content-between mb-4"
                  key={el?._id}
                >
                  <div className="d-flex align-items-center">
                    <div className="prod-thumb me-3">
                      <img
                        src={imgUrl + el?.productId?.thumbnailImage}
                        alt="thumb"
                      />
                      <div className="product-count">
                        <span>{el?.quantity}</span>
                      </div>
                    </div>
                    <div>
                      <h6 className="m-0 fw-light">
                        {el?.productId?.name}
                      </h6>
                    </div>
                  </div>
                  <div>
                    <span>₹ {el?.price}</span>
                  </div>
                </div>
              ))}
            </div>
            <div className="sub-total d-flex justify-content-between mb-3">
              <input
                className="form-control me-2"
                placeholder="Coupon Code"
                type="text"
                name="couponCode"
                value={formik.values.couponCode}
                onChange={formik.handleChange}
              />
              <button
                className="btn btn-sm btn-secondary"
                type="button"
                onClick={applyCoupon}
              >
                Apply
              </button>
            </div>
            {showAlert && <Alert variant="danger">{alertMessage}</Alert>}
            <div className="sub-totla d-flex justify-content-between">
              <h6>Subtotal</h6>
              <span>₹ {cartTotal}</span>
            </div>
            {isvalidCoupon && (
              <div className="sub-totla d-flex justify-content-between">
                <h6>less Coupon Discount</h6>
                <span>-₹ {(mycoupon.percentage / 100) * cartTotal}</span>
              </div>
            )}
            <div className="total d-flex justify-content-between">
              <h5>Total</h5>
              <span>
                <small>INR</small> ₹ {amount}
              </span>
            </div>
          </>
        ) : (
          <Alert variant="danger">No items in the cart</Alert>
        )}
      </div>
    </Col>

  );
}

//##################################################################################################
//##################################################################################################


// import React, { useEffect, useState } from "react";
// import { Alert, Col, Container, Form, Row } from "react-bootstrap";
// import "./Checkout.css";
// import { useNavigate } from "react-router-dom";

// import LOGO from "./wincenzo_logo.png";
// import { useDispatch, useSelector } from "react-redux";
// import { deleteCartAllItem, getCart } from "../../features/cart/cartSlice";
// import { useFormik } from "formik";
// import { addOrders, allOrders } from "../../features/auth/authSlice";
// import axios from "axios";
// import { baseUrl } from "../../configs/baseUrl";
// import { countries, states } from "./options";
// import { getCoupons } from "../../features/product/productSlice";

// export default function Checkout() {

//   const { cart, cartTotal } = useSelector((state) => state?.cart);
//   const { coupons } = useSelector((state) => state?.product);
//   const { user } = useSelector((state) => state?.auth);
//   const imgUrl = process.env.REACT_APP_BASE_URL_IMAGE;

//   let deliveryAddress = user?.shippingAddresses

//   const dispatch = useDispatch();
//   const navigate = useNavigate();

//   const [showAlert, setShowAlert] = useState(false);
//   const [alertMessage, setAlertMessage] = useState("");
//   const [amount, setAmount] = useState(cartTotal)
//   const [isvalidCoupon, setIsValidCoupon] = useState(false)
//   const [mycoupon, setMyCoupon] = useState("")

//   useEffect(() => {
//     setAmount(cartTotal)
//   }, [cartTotal]);

//   useEffect(() => {
//     dispatch(getCoupons());
//   }, [dispatch, cart]);

//   useEffect(() => {

//     if (user?._id) dispatch(getCart(user?._id));
//   }, [dispatch, user._id]);

//   const applyCoupon = () => {
//     const { couponCode } = formik.values;

//     if (!couponCode) {
//       setAlertMessage("Please enter a coupon code.");
//       setShowAlert(true);
//       return;
//     }

//     const coupon = coupons.find((coupon) => coupon.code === couponCode);

//     if (!coupon) {
//       setAlertMessage("Invalid coupon code. Please enter a valid code.");
//       setShowAlert(true);
//       return;
//     };

//     const currentDate = new Date();
//     const startDate = new Date(coupon.startDate);
//     const endDate = new Date(coupon.expirationDate);

//     if (currentDate < startDate || currentDate > endDate) {
//       setAlertMessage("Coupon is expired.");
//       setShowAlert(true);
//       return;
//     }

//     const applicableProducts = cart.filter((item) =>
//       coupon.products?.includes(item.productId?._id)
//     );
//     if (applicableProducts.length === 0) {
//       setAlertMessage("No products in the cart are applicable for this coupon")
//       setShowAlert(true);
//       return;
//     }
//     setIsValidCoupon(true)
//     setMyCoupon(coupon)
//     // Calculate discount
//     const discountAmount = (coupon.percentage / 100) * cartTotal;
//     const totalAfterDiscount = cartTotal - discountAmount;
//     setAmount(totalAfterDiscount)

//   };

//   const products = [];
//   cart?.forEach((e) => {
//     products?.push({
//       product: e?.productId?._id,
//       quantity: e?.quantity,
//     });
//   });

//   const getAvailablePaymentMethods = () => {
//     const paymentMethods = new Set();
//     cart.forEach((item) => {
//       // Assuming 'paymentMethod' is a property of 'item.productId'
//       if (!paymentMethods.has(item.productId.paymentMethod)) {
//         paymentMethods.add(item.productId.paymentMethod);
//       }
//     });
//     return Array.from(paymentMethods);
//   };

//   // State to hold available payment methods
//   const [availablePaymentMethods, setAvailablePaymentMethods] = useState([]);

//   useEffect(() => {
//     setAvailablePaymentMethods(getAvailablePaymentMethods());
//   }, [cart]);


//   //**************razorpay function for checkout***************//

//   const checkoutHandler = async (e, amount) => {
//     e.preventDefault()

//     if (formik.values.paymentMethod === "Online Payment") {
//       const { data: { key } } = await axios.get(`${baseUrl}payment/keyid`)
//       const { data: { order } } = await axios.post(`${baseUrl}payment/checkout`, { amount })

//       const options = {
//         key,
//         amount: order.amount,
//         currency: "INR",
//         name: "Wincenzo",
//         description: "Wincenzo Online Payment",
//         image: LOGO,
//         order_id: order.id,
//         callback_url: `${baseUrl}payment/paymentverification`,
//         prefill: {
//           name: user?.username,
//           email: user?.email,
//           contact: user?.phoneNumber,
//         },
//         notes: {
//           "address": "razorapy official"
//         },
//         theme: {
//           "color": "#F99A00"
//         }
//       };
//       const razor = new window.Razorpay(options);
//       razor.open();
//       formik.handleSubmit(amount);
//     } else {
//       // For Cash on Delivery or any other payment method, proceed with order submission
//       formik.handleSubmit(amount);
//     }
//   }

//   const formik = useFormik({
//     enableReinitialize: true,
//     initialValues: {
//       products: products,
//       userId: user?._id,
//       total: amount,
//       discount: "",
//       couponCode: "",
//       shippingAddress: {
//         label: "",
//         address: "",
//         zipCode: "",
//         country: "",
//         state: "",
//         city: "",
//       },
//       paymentMethod: "",
//       orderTotal: cartTotal
//     },
//     onSubmit: (e, amount) => {

//       let discount = "";
//       let couponCode = "";

//       if (isvalidCoupon && mycoupon) {
//         discount = mycoupon.percentage
//         couponCode = mycoupon.code;
//       }
//       const data = {
//         products: e?.products,
//         userId: e?.userId,
//         total: e?.total,
//         discount: discount || 0,
//         couponCode: couponCode || "xxxx",
//         shippingAddress: e?.shippingAddress,
//         paymentMethod: e?.paymentMethod,
//         orderTotal: e?.orderTotal,
//       };





//       dispatch(addOrders(data));
//       setIsValidCoupon(false)
//       setMyCoupon("")
//       setTimeout(async () => {
//         try {
//           // Dispatch allOrders action to fetch updated orders
//           if (user?._id) {
//             await dispatch(allOrders(user?._id));
//           }

//           // Get array of product IDs
//           const productIds = cart.map(item => item._id);

//           // Dispatch deleteCart action
//           await dispatch(deleteCartAllItem(productIds));

//           // Reset form
//           formik.resetForm();
//           // Navigate to the orders page

//         } catch (error) {
//           console.error("Error deleting cart items or fetching orders:", error);
//         }
//       }, 900);
//     },
//   });



//   return (
//     <>
//       <div className="checkout-section">
//         <Container>
//           <form className="checkout-compo">
//             <Col lg={7} md={6} className="address-detail">
//               <div className="delivery-add">
//                 <div className="mb-3">
//                   <h5 className="m-0 mb-3">Select Address</h5>
//                   {deliveryAddress.length > 0 ?
//                     <div className="d-flex flex-column ">
//                       {deliveryAddress.map((item, index) => (
//                         <div key={index} className="mb-3 border border-1 rounded overflow-hidden">
//                           <div className="bg-dark-subtle d-flex align-items-cente border-bottom px-2 py-1 ">
//                             <Form.Check
//                               type="radio"
//                               id={`address${index}`}
//                               name="selectedAddress"
//                               value={item.address}
//                               checked={formik.values.shippingAddress.address === item.address}
//                               onChange={() => formik.setFieldValue("shippingAddress", item)}
//                               className="me-3"
//                             />
//                             <Form.Group>
//                               <Form.Label className="m-0" htmlFor={`address${index}`}>
//                                 {`Address ${index + 1}`}
//                               </Form.Label>
//                             </Form.Group>
//                           </div>
//                           <address className="m-0 px-2 py-1">
//                             {item.address}, {item.city}, {item.state}, {item.country}, {item.zipCode}
//                           </address>
//                         </div>
//                       ))}
//                     </div>
//                     :
//                     <Alert variant="danger">Add a new address for the order below</Alert>
//                   }
//                 </div>
//                 <div className="mb-3">
//                   <h5 className="m-0 mb-3">Delivery Address</h5>

//                   <div className="country-input mb-3">
//                     <Form.Select
//                       aria-label="Default select example"
//                       onChange={formik.handleChange("shippingAddress.country")}

//                     >
//                       {countries.map(country => (
//                         <option key={country.value} value={country.value}>{country.label}</option>
//                       ))}
//                     </Form.Select>
//                   </div>
//                   <div className="name-detail mb-3 ">
//                     <Row>
//                       <Col lg={12} md={12} className="mb-3">
//                         <Form.Control
//                           className=""
//                           type="text"
//                           placeholder="Name"
//                           onChange={formik.handleChange("shippingAddress.name")}
//                           value={formik.values.shippingAddress.name}
//                         />
//                       </Col>
//                     </Row>
//                   </div>
//                   <div className="addr-detail mb-3">
//                     <Form.Control type="text"
//                       placeholder="Address"
//                       onChange={formik.handleChange("shippingAddress.address")}
//                       value={formik.values.shippingAddress.address}
//                     />
//                   </div>
//                   <div className="pin-detail d-flex mb-3">
//                     <Row className="flex-wrap">
//                       <Col lg={4} md={6}>
//                         <Form.Control
//                           className="mb-3 me-3 city"
//                           type="text"
//                           //   name="shippingAddress.city"
//                           placeholder="City"
//                           onChange={formik.handleChange("shippingAddress.city")}
//                           value={formik.values.shippingAddress.city}
//                         />
//                       </Col>
//                       <Col lg={4} md={6}>
//                         <Form.Select
//                           aria-label="Default select example"
//                           className="mb-3 me-3"
//                           onChange={formik.handleChange(
//                             "shippingAddress.state"
//                           )}
//                         >
//                           {states.map(state => (
//                             <option key={state.value} value={state.value}>{state.label}</option>
//                           ))}

//                         </Form.Select>
//                       </Col>
//                       <Col lg={4} md={6}>
//                         <Form.Control
//                           className="mb-3 pincode"
//                           type="text"
//                           //   name="shippingAddress.zipCode"
//                           placeholder="PIN code"
//                           onChange={formik.handleChange(
//                             "shippingAddress.zipCode"
//                           )}
//                           value={formik.values.shippingAddress.zipCode}
//                         />
//                       </Col>
//                     </Row>
//                   </div>

//                   <div className="payment mb-4">
//                     <h5 className="m-0 mb-1">Payment</h5>
//                     <p className="m-0 mb-3">
//                       All transactions are secure and encrypted.
//                     </p>
//                     <div className="d-flex flex-column gap-2">
//                       {availablePaymentMethods.includes("BOTH") && (
//                         <>
//                           <div className="d-flex gap-2">
//                             <input
//                               type="radio"
//                               id="Online Payment"
//                               name="paymentMethod"
//                               value="Online Payment"
//                               checked={formik.values.paymentMethod === "Online Payment"}
//                               onChange={formik.handleChange}
//                             />
//                             <label className="form-check-label" htmlFor="Online Payment">
//                               Online Payment
//                             </label>
//                           </div>
//                           <div className="d-flex gap-2">
//                             <input
//                               type="radio"
//                               id="Cash on Delivery"
//                               name="paymentMethod"
//                               value="Cash on Delivery"
//                               checked={formik.values.paymentMethod === "Cash on Delivery"}
//                               onChange={formik.handleChange}
//                             />
//                             <label className="form-check-label" htmlFor="Cash on Delivery">
//                               Cash on Delivery
//                             </label>
//                           </div>
//                         </>
//                       )}
//                       {availablePaymentMethods.includes("ONLINE") && (
//                         <div className="d-flex gap-2">
//                           <input
//                             type="radio"
//                             id="Online Payment"
//                             name="paymentMethod"
//                             value="Online Payment"
//                             checked={formik.values.paymentMethod === "Online Payment"}
//                             onChange={formik.handleChange}
//                           />
//                           <label className="form-check-label" htmlFor="Online Payment">
//                             Online Payment
//                           </label>
//                         </div>
//                       )}
//                       {availablePaymentMethods.includes("COD") && (
//                         <div className="d-flex gap-2">
//                           <input
//                             type="radio"
//                             id="Cash on Delivery"
//                             name="paymentMethod"
//                             value="Cash on Delivery"
//                             checked={formik.values.paymentMethod === "Cash on Delivery"}
//                             onChange={formik.handleChange}
//                           />
//                           <label className="form-check-label" htmlFor="Cash on Delivery">
//                             Cash on Delivery
//                           </label>
//                         </div>
//                       )}

//                     </div>
//                   </div>
//                   <div className="complete-btn">

//                     <button
//                       className="comp-order-btn"
//                       type="button"
//                       onClick={(e) => checkoutHandler(e, amount)}
//                     >Complete Order</button>
//                   </div>
//                 </div>
//               </div>
//             </Col>

//             <Col lg={5} md={6} sm={12} className="cart-detail">
//               <div className="order-detail">
//                 <h5 className="m-0 mb-3">Order Summary</h5>

//                 <div className="m-3">
//                   {cart?.map((el) => {
//                     return (

//                       <div
//                         className="product-detail d-flex align-items-center justify-content-between mb-4"
//                         key={el?._id}
//                       >
//                         <div className="d-flex align-items-center">
//                           <div className="prod-thumb me-3">
//                             <img
//                               src={imgUrl + el?.productId?.thumbnailImage}
//                               className=""
//                               alt="thumb"
//                             />
//                             <div className="product-count">
//                               <span>{el?.quantity}</span>
//                             </div>
//                           </div>
//                           <div>
//                             <h6 className="m-0 fw-light">
//                               {el?.productId?.name}
//                             </h6>
//                           </div>
//                         </div>
//                         <div className="">
//                           <span>₹ {el?.price}</span>
//                         </div>
//                       </div>

//                     );
//                   })}
//                 </div>
//                 <div className="sub-total d-flex justify-content-between mb-3">
//                   <input
//                     className="form-control me-2"
//                     placeholder="Coupon Code"
//                     type="text"
//                     name="couponCode"
//                     value={formik.values.couponCode}
//                     onChange={formik.handleChange}
//                   />
//                   <button
//                     className="btn btn-sm btn-secondary"
//                     type="button"
//                     onClick={applyCoupon}
//                   >
//                     Apply
//                   </button>
//                 </div>
//                 {showAlert && <Alert variant="danger">{alertMessage}</Alert>}
//                 <div className="sub-totla d-flex justify-content-between">
//                   <h6 className="">Subtotal</h6>
//                   <span>₹ {cartTotal}</span>
//                 </div>
//                 {
//                   isvalidCoupon && <div className="sub-totla d-flex justify-content-between">
//                     <h6 className="">less Coupon Discount</h6>
//                     <span>-₹ {(mycoupon.percentage / 100) * cartTotal}</span>
//                   </div>
//                 }
//                 <div className="total d-flex justify-content-between">
//                   <h5 className="">Total</h5>
//                   <span>
//                     <small>INR</small> ₹ {amount}
//                   </span>
//                 </div>
//               </div>
//             </Col>
//           </form>
//         </Container>
//       </div>
//     </>
//   );
//}
