import React, { useEffect } from "react";
import "./Login.css";
import { Col, Container, Row } from "react-bootstrap";
import loginbanner from "./img/Login-pana.svg";
import { useFormik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { resendOTP, sendOTP } from "../../../features/auth/authSlice";

export default function LoginOtp() {
  const schema = yup.object().shape({
    phoneNumber: yup.string()
      .max(10, "Mobile Number must be exactly 10 digits")
      .matches(/^\d+$/, "Mobile Number must be a valid number")
      .required("Mobile Number is Required"),
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isSuccess } = useSelector((state) => state?.auth);

  const formik = useFormik({
    initialValues: {
      phoneNumber: "",
    },
    validationSchema: schema,
    onSubmit: (values) => {
      dispatch(sendOTP(values.phoneNumber));
    },
  });

  useEffect(() => {
    if (isSuccess) {
      navigate(`/login-verify-otp?${formik.values?.phoneNumber}`);
    }
  }, [isSuccess, formik.values?.phoneNumber, navigate]);

  const handleResendOTP = () => {
    dispatch(resendOTP(formik.values?.phoneNumber));
  };

  const handlePhoneNumberChange = (event) => {
    const { value } = event.target;
    const formattedValue = value.replace(/\D/g, '').slice(0, 10);
    formik.setFieldValue('phoneNumber', formattedValue);
  };

  return (
    <div className="login-pag m-5 py-4">
      <Container>
        <Row className="align-items-center justify-content-center">
          <Col lg={6} md={6} className="login-left">
            <img src={loginbanner} className="img-fluid" alt="loginbanner" />
          </Col>
          <Col lg={4} md={6} className="login-right">
            <div className="login-header d-flex justify-content-between align-items-center">
              <h5>
                Login to <span>Wincenzo</span>
              </h5>
            </div>
            <form onSubmit={formik.handleSubmit}>
              <div className="mb-3 form-focus">
                <label className="focus-label">Mobile Number</label>
                <input
                  type="tel"
                  name="phoneNumber"
                  onChange={handlePhoneNumberChange}
                  value={formik.values.phoneNumber}
                  className="form-control floating"
                />
                <div className="text-danger err-text">
                  {formik.touched.phoneNumber && formik.errors.phoneNumber}
                </div>
              </div>

              <div className="login-header d-flex justify-content-between align-items-center">
                <button
                  className="btn btn-primary btn-sm login-btn mb-3"
                  type="submit"
                >
                  Request for OTP
                </button>
                <button
                  className="btn btn-success btn-sm login-btn mb-3"
                  onClick={handleResendOTP}
                >
                  Resend OTP
                </button>
              </div>

              <div className="dont-have">
                Don’t have an account? <Link to="/register">Register</Link>
              </div>
            </form>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

















// import React, { useEffect } from "react";
// import "./Login.css";
// import { Col, Container, Row } from "react-bootstrap";
// import loginbanner from "./img/Login-pana.svg";
// import { useFormik } from "formik";
// import { Link, useNavigate } from "react-router-dom";
// import * as yup from "yup";
// import { useDispatch, useSelector } from "react-redux";
// import { resendOTP, sendOTP } from "../../../features/auth/authSlice";


// export default function LoginOtp() {
//   let schema = yup.object().shape({
//     phoneNumber: yup.string().max(10, "Mobile Number must be exactly 10 digits")
//     .matches(/^\d+$/, "Mobile Number must be a valid number")
//     .required("Mobile Number is Required"),
//   });

//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const { isSuccess } = useSelector((state) => state?.auth);

//   const formik = useFormik({
//     initialValues: {
//       phoneNumber: "",
//     },
//     validationSchema: schema,
//     onSubmit: (values) => {
//       dispatch(sendOTP(values.phoneNumber));
//     },
//   });

//   useEffect(() => {
//     if (isSuccess) {
//       navigate(`/login-verify?${formik.values?.phoneNumber}`);
//     }
//   }, [isSuccess])

//   const handleResendOTP = () => {
//     dispatch(resendOTP(formik.values?.phoneNumber))
//   };

//   const handlePhoneNumberChange = (event) => {
//     const { value } = event.target;
//     // Allow only numbers and limit to 10 digits
//     const formattedValue = value.replace(/\D/g, '').slice(0, 10);
//     formik.setFieldValue('phoneNumber', formattedValue);
//   };




//   return (
//     <>
//       <div className="login-pag m-5 py-4">
//         <Container className="">
//           <Row className="align-items-center justify-content-center">
//             <Col lg={6} md={6} className="login-left">
//               <img src={loginbanner} className="img-fluid" alt="loginbanner" />
//             </Col>
//             <Col lg={4} md={6} className="login-right">
//               <div className="login-header d-flex justify-content-between align-items-center">
//                 <h5>
//                   Login to <span>Wincenzo</span>
//                 </h5>
//                 {/* <div className="mb-2 f-pass d-flex  ">
//                   <Link className="forgot-link" to="/signin">
//                     Login via email
//                   </Link>
//                 </div> */}
//               </div>
//               <form onSubmit={formik.handleSubmit}>
//                 <div className="mb-3 form-focus">
//                   <label className="focus-label">Mobile Number</label>
//                   <input
//                     type="tel"
//                     name="phoneNumber"
//                     onChange={handlePhoneNumberChange}
//                     value={formik.values.phoneNumber}
//                     className="form-control floating"
//                   />
//                   <div className="text-danger err-text">
//                     {formik.touched.phoneNumber && formik.errors.phoneNumber}
//                   </div>
//                 </div>

//                 <div className="login-header d-flex justify-content-between align-items-center">
//                   <button
//                     className="btn btn-primary btn-sm login-btn mb-3"
//                     type="submit"
//                   >
//                     Request for OTP
//                   </button>
//                   <button
//                     className="btn btn-success btn-sm login-btn mb-3"
//                     onClick={handleResendOTP}
//                   >
//                     Resend OTP
//                   </button>
//                 </div>

//                 <div className="dont-have ">
//                   Don’t have an account? <Link to="/register">Register</Link>
//                 </div>
//               </form>
//             </Col>
//           </Row>
//         </Container>
//       </div>
//     </>
//   );
// }
