import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authService, { sendOrderEmail } from "./authService";
import toast from "react-hot-toast";
// import { toast } from "react-toastify";

const getUserLocalStorage = window.localStorage.getItem("USER")
  ? JSON.parse(window.localStorage.getItem("USER"))
  : null;

const initialState = {
  user: getUserLocalStorage,
  token:null,
  orders: [],
  wishlist: [],
  isError: false,
  isLoading: false,
  isSuccess: false,
  isVerified: false,
  isRegistered: false,
  isLoggedIn: false,
  message: "",
  registerUser: {},
};

export const login = createAsyncThunk(
  "auth/user-login",
  async (data, thunkAPI) => {
    try {
      return await authService.login(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);


export const register = createAsyncThunk(
  "auth/user-register",
  async (user, thunkAPI) => {
    try {
      return await authService.reg(user);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);


export const editUser = createAsyncThunk(
  "auth/user-update",
  async (data, thunkAPI) => {
    try {
      return await authService.updateUser(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "auth/user-forgot-password",
  async (data, thunkAPI) => {
    try {
      return await authService.forgotPass(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const forgotPasswordVerify = createAsyncThunk(
  "auth/user-forgot-password-verify",
  async (data, thunkAPI) => {
    try {
      return await authService.forgotPassVerify(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const allOrders = createAsyncThunk(
  "order/get-user-orders",
  async (data, thunkAPI) => {
    try {
      return await authService.allOrders(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const addOrders = createAsyncThunk(
  "order/add-user-orders",
  async (data, thunkAPI) => {
    try {
      return await authService.addOrders(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const LoginOTP = createAsyncThunk(
  "auth/user-login/OTP",
  async (user, thunkAPI) => {
    try {
      return await authService.loginotp(user);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const getWishlist = createAsyncThunk(
  "user/get-wishlist",
  async (user, thunkAPI) => {
    try {
      return await authService.getwishlist(user);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const addWishlist = createAsyncThunk(
  "user/add-wishlist",
  async (user, thunkAPI) => {
    try {
      return await authService.addwishlist(user);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const deleteWishlist = createAsyncThunk(
  "user/delete-wishlist",
  async (user, thunkAPI) => {
    try {
      return await authService.deletewishlist(user);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);


//#############################################################################//
//###############  Regiser and login by OTP (Third Party Integration)##########################//

export const sendOTP = createAsyncThunk(
  "auth/otp/send-otp",
  async (data, thunkAPI) => {
    try {
      return await authService.sendOTP(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const verifyOTP = createAsyncThunk(
  "auth/otp/verify-otp",
  async (data, thunkAPI) => {
    
    try {
      return await authService.verifyOTP(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const loginbyOTP = createAsyncThunk(
  "auth/otp/loginby-otp",
  async (data, thunkAPI) => {
   
    try {
      return await authService.loginByOtp(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const resendOTP = createAsyncThunk(
  "auth/otp/resend-otp",
  async (data, thunkAPI) => {
    try {
      return await authService.resendOTP(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const sendOrderEmailThunk = createAsyncThunk(
  'email/sendOrderEmail',
  async (orderDetails, { rejectWithValue }) => {
    try {
      const data = await sendOrderEmail(orderDetails);
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


export const fetchToken = createAsyncThunk('shiprocket/fetchToken', async (_, thunkAPI) => {
  try {
      const response = await authService.getToken();
      return response.data.token;
  } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
  }
});




export const authSlice = createSlice({
  name: "auth",
  initialState,
  successMessage:"",
  reducers: {
    handleLogout: (state, action) => {
      window.localStorage.removeItem("USER");
      window.localStorage.removeItem("TOKEN");
      state.user = null;
      state.isVerified = false;
      state.isRegistered = false;
      state.isLoggedIn = false;
      toast.success("User logged out");
    },

  },





  
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.user = action.payload.user;
        if (state.isSuccess === true) {
          toast.success(action.payload.message);
          // setTimeout(() => {
          //   window.location.reload();
          // }, 300);
        }

        state.isError = false;
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.user = null;
        toast.error(action.payload?.response?.data?.message);
      })
      .addCase(register.pending, (state, action) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(register.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isRegistered = true;
        state.registerUser = action.payload.user;
        if (state.isSuccess === true) {
          toast.success(action.payload.message);

        }
        state.isError = false;
      })
      .addCase(register.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.user = null;
        if (state.isError === true) {
          toast.error(action?.payload?.response?.data?.message);
        }
      })

      .addCase(editUser.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(editUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        toast.success(action.payload.message);
        localStorage.setItem("USER", JSON.stringify(action.payload?.user));

        state.isError = false;
      })
      .addCase(editUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;

        toast.error(action.payload?.response?.data?.message);
      })
      .addCase(fetchToken.pending, (state) => {
        state.status = 'loading';
    })
    .addCase(fetchToken.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.token = action.payload;
    })
    .addCase(fetchToken.rejected, (state, action) => {
        state.status = 'failed';
        state.isError = action.payload;
    })

      .addCase(forgotPassword.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        toast.success(action.payload.message);
        state.isError = false;
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;

        toast.error(action.payload?.response?.data?.message);
      })
      .addCase(forgotPasswordVerify.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(forgotPasswordVerify.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        toast.success(action.payload.message);
        state.message = action.payload.message;
        state.isError = false;
      })
      .addCase(forgotPasswordVerify.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;

        toast.error(action.payload?.response?.data?.message);
      })
      .addCase(allOrders.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(allOrders.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.orders = action.payload;
      })
      .addCase(allOrders.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(LoginOTP.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(LoginOTP.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        toast.success(action.payload.message);
        state.isError = false;
      })
      .addCase(LoginOTP.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;

        toast.error(action.payload?.response?.data?.message);
      })
      .addCase(addOrders.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(addOrders.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        toast.success(action.payload.message);
        state.isError = false;
      })
      .addCase(addOrders.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;

        toast.error(action.payload?.response?.data?.message);
      })
      .addCase(addWishlist.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(addWishlist.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        toast.success(action.payload.message);
        state.isError = false;
      })
      .addCase(addWishlist.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;

        toast.error(action.payload?.response?.data?.message);
      })
      .addCase(getWishlist.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(getWishlist.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.wishlist = action.payload.wishlistItems;
        state.wishlistCount = action.payload.wishlistCount;
      })
      .addCase(getWishlist.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;

        toast.error(action.payload?.response?.data?.message);
      })
      .addCase(deleteWishlist.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(deleteWishlist.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        toast.success(action.payload.message);
        state.isError = false;
      })
      .addCase(deleteWishlist.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;

        toast.error(action.payload?.response?.data?.message);
      })

      .addCase(sendOrderEmailThunk.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(sendOrderEmailThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.successMessage = action.payload.message;
      })
      .addCase(sendOrderEmailThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      //###############  Regiser and login by OTP (Third Party Integration)##########################//
      .addCase(sendOTP.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
      })
      .addCase(sendOTP.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        toast.success(action.payload.message);
      })
      .addCase(sendOTP.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        toast.error(action.payload?.response?.data?.error);
      })
      .addCase(verifyOTP.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
      })
      .addCase(verifyOTP.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.isVerified = true;
        toast.success(action.payload.message);
      })
      .addCase(verifyOTP.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        toast.error(action.payload?.response?.data?.error);
      })
      .addCase(resendOTP.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
      })
      .addCase(resendOTP.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        toast.success(action.payload.message);
      })
      .addCase(resendOTP.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        toast.error(action.payload?.response?.data?.error);
      })
      .addCase(loginbyOTP.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
      })
      .addCase(loginbyOTP.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.isLoggedIn = true;
        state.isVerified = false;
        state.user = action.payload.user;
        if (state.isSuccess === true) {
          toast.success(action.payload.message);
        }
      })
      .addCase(loginbyOTP.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.user = null;
        toast.error(action.payload?.response?.data?.error);
      });
  },
});

export const { handleLogout } = authSlice.actions;
export default authSlice.reducer;
