import React, { useEffect, useState } from 'react';
import { Button, Container, Form, ProgressBar as BootstrapProgressBar, Row, Col } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import './Testpage.css'; // Import custom styles
import productImage from './thumbnailImage-1715175193186-383385148A2 K1 C1 T1.png'; // Import the image
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addNewTestUser, getSuggestedProduct } from '../../features/testReducer/testUserSlice';
import { getProducts } from '../../features/product/productSlice';

const TestPage = () => {
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [answers, setAnswers] = useState(Array(6).fill([]));
    const [showResult, setShowResult] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch()
    const imgUrl = process.env.REACT_APP_BASE_URL_IMAGE;

    const { products } = useSelector((state) => state?.product);
    const { suggestedProduct } = useSelector((state) => state.testUsers);
    const sugegestedproductId = suggestedProduct?.productId

    const suggestedProducImage = products.find(product => product._id === sugegestedproductId)

    useEffect(() => {
        dispatch(getProducts());
    }, [dispatch]);
    useEffect(() => {
        dispatch(getSuggestedProduct());
    }, [dispatch]);


    const [currentStep, setCurrentStep] = useState(0);
    const [userDetailStep, setUserDetailStep] = useState(0);

    const steps = ["About You", "Your Health", "Result"];

    const questions = [
        { id: 1, text: 'તમને હરસ મસા,ભગંદર,ફિશર-ફીસ્ટુલા,વાઢીયા પેક્કી કઈ સમ્સીયા છે?', options: ['હરસ - મસા', 'ફિશર -ફીસ્ટુલા', 'ભગંદર', 'વાઢીયા', 'તમને જાણ નથી...'] },
        { id: 2, text: 'તમને શું શું સમસ્યા થાય છે?', options: ['બળતરા રહે છે', 'લોહી પડે છે', 'દુખાવો રહે છે', 'બેસી શકાતું નથી', 'મસા બહાર આવે છે', 'રસી પડે છે', 'રીંગ માં સોજો રહે છે', 'ખંજવાળ આવે છે', 'તમને જાન નથી'] },
        { id: 3, text: 'કોઈ ડોક્ટર ને બતાવેલ છે?', options: ['હા', 'ના', 'ઘણા દવા કરાવી છતાં ફર્ક નથી'] },
        { id: 4, text: 'કેટલા ટાઇમ થી આ સમસ્યા છે?', options: ['1 મહિના', '૨ મહિના', '3 મહીનાથીન વધારે', '1 વર્ષ કે તેનાથીન વધારે', 'તીખું ખવાય ત્યારે જ પ્રોબ્લેમ આવે છે'] },
        { id: 5, text: 'તમને કબજિયાત રહે છે?', options: ['હા', 'ના', 'બહાર નું ખવાય ત્યારે જ', 'ક્યારેક ક્યારેક', 'મેંદા વાળું ખવાય ત્યારે'] },
        { id: 6, text: 'કબજીયાત માં શું શું પ્રોબલેમ થાય છે?', options: ['વારંવાર ટોયલેટ જાવું પડે છે', 'ટોયલેટ કરતી વખતે દુખાવો થાય છે', 'ટોયલેટ માં બળ કરવું પડે કે લોહી પડે', 'પેટ ભારે લાગે છે', 'પેટ માં દુઉખવો થવા લાગે છે'] },
    ];
    const progress = ((currentStep + currentQuestionIndex / questions.length) / steps.length) * 100;

    // State for user details
    const [userDetails, setUserDetails] = useState({ name: '', mobile: '', email: '' });

    // Yup validation schema
    const validationSchema = Yup.object({
        name: Yup.string().required('Name is required'),
        mobile: Yup.string().required('Mobile is required').matches(/^[0-9]+$/, "Must be only digits").min(10, 'Must be exactly 10 digits').max(10, 'Must be exactly 10 digits'),
        email: Yup.string().email('Invalid email format').required('Email is required'),
    });

    // Formik initialization
    const formik = useFormik({
        initialValues: { name: '', mobile: '', email: '' },
        validationSchema,
        onSubmit: (values) => {
            setUserDetails(values);
            setCurrentStep(currentStep + 1);
        },
    });

    const handleNextUserDetailStep = (step) => {
        // Trigger validation for the current field
        if (step === 0) {
            formik.setFieldTouched('name', true);
            if (!formik.errors.name && formik.values.name) {
                setUserDetailStep(1);
            }
        } else if (step === 1) {
            formik.setFieldTouched('mobile', true);
            if (!formik.errors.mobile && formik.values.mobile) {
                setUserDetailStep(2);
            }
        } else if (step === 2) {
            formik.setFieldTouched('email', true);
            if (!formik.errors.email && formik.values.email) {
                formik.handleSubmit();
            }
        }
    };

    const handleNextQuestion = async () => {
        if (currentQuestionIndex < questions.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        } else {
            setShowResult(true);
            try {

                await dispatch(addNewTestUser({ userDetails, answers }))
            } catch (error) {
                console.error('Error submitting health answers:', error);
            }
        }
    };

    const handlePrevQuestion = () => {
        if (currentQuestionIndex > 0) {
            setCurrentQuestionIndex(currentQuestionIndex - 1);
        }
    };

    const handleSelectOption = (option) => {
        const updatedAnswers = [...answers];
        const currentAnswers = updatedAnswers[currentQuestionIndex];
        if (currentAnswers.includes(option)) {
            updatedAnswers[currentQuestionIndex] = currentAnswers.filter((o) => o !== option);
        } else {
            updatedAnswers[currentQuestionIndex] = [...currentAnswers, option];
        }
        setAnswers(updatedAnswers);
    };

    return (
        <Container className="my-4 d-flex flex-column align-items-center py-5">
            {showResult ? (
                <Row className="text-center align-items-center justify-content-between">
                    <h5 className="mb-4 question-title">Test Completed!</h5>
                    <h5 className="mb-4">Treatment Kit</h5>
                    <Col lg={4} md={6}>
                        <div>
                            <img src={imgUrl + suggestedProducImage?.thumbnailImage} alt="Product" className="result-image" />
                        </div>
                        <h5>૨ અર્શિત સીરપ + ૧ SBLX ચૂર્ણ + ૧ અર્શિત કેપ્સ્યુલ +૧ અર્શિત ટ્યુબ</h5>
                    </Col>
                    <Col lg={7} md={6}>
                        <div className="result-text">
                            <h5 className="mb-3">આખી કીટ ઉપયોગ કરવાનું કારણ?</h5>
                            <ul className="mb-3">
                                <li>અર્શિત ની જે સીરપ આવે છે તે શરીરની અંદરની ગરમી તથા આતરની ગરમીને બહાર કાઢવામાં મદદરૂપ કરે છે
                                    તથા તેની અંદર આવતી જડીબુટ્ટી નિરંજન ફળ હરસ મસા ભગંદર ફિશર ફેસ્ટુલા જેવા મળમાર્ગ ના બધા જ રોગોની
                                    અંદર રામબાણ ઔષધી છે આ કીટમાં તમને અર્શિત ટ્યુબ પણ મળશે જે મળ માર્ગની અંદર ખંજવાળ તથા
                                    ઇન્ફેક્શનને ફેલતા અટકાવે છે આની સાથે SBLX કબજિયાત ચૂર્ણ પણ આવશે જે શરીરના પાચનતંત્ર અને
                                    મજબૂત કરશે અને જો શરીરમાં કબજિયાત રહેતી હોય તો ટેવ પાડ્યા વગર તેને દૂર કરશે સાથે આ કીટમાં
                                    અર્શિત કેપ્સુલ પણ નાખવામાં આવી છે જે મસા ને સૂકવવામાં મદદ કરે છે
                                </li>
                            </ul>
                            <h5 className="mb-3">અર્શિત કીટ લેવાના ફાયદા?</h5>
                            <ul className="mb-3">
                                <li>આર્શિત કીટ એક આયુર્વેદિક ઉપચાર છે જે હરસ મસા માટે 100% અસરકારક ઉપાય છે
                                    પરંતુ આ મેડિસિન ને અસર કરવામાં અમુક સમય ગાળો લાગે છે શરૂઆતના દસથી પંદર
                                    દિવસમાં આર્શિતના રેગ્યુલર ઉપયોગથી દુખાવો અને બળતરા જેવી સમસ્યામાં રાહત મળે છે
                                    પરંતુ જે મસા બહાર આવેલા હોય તથા રિંગમાં સોજો આવતો હોય તથા હરસ મસા ફિશર
                                    ફિસ્તુલા ને લગતી કોઈપણ સમસ્યા હોય તો તેને સરખી થવા માં નોર્મલી ત્રણ મહિના જેટલો કોર્સ કરવો ફરજીયાત છે
                                    આર્શિત દવામાં આયુર્વેદિક ઔષધીઓ જેવીકે નિરંજન ફળ, દારૂ અરીઠા, અરીઠા છાલ નાખવામાં આવે છે
                                    જે હરસ મસા ભગંદર ફીશર ફિશતુલા જેવા રોગોમાં રામબાણ રીતે ઔષધિ નું કામ કરે છે બાકી તમે
                                    આના વિશે વધારે માહિતી નીચે આપેલા નંબર અથવા તો કસ્ટમર કેર નંબર માં કોલ કરીને નિષ્ણાતો
                                    સાથે વાત કરી વધારે માહિતી મેળવી શકો છો
                                </li>
                            </ul>
                            <button className="buy-now-btn" onClick={() => navigate(`/product/${sugegestedproductId}`)}>BUY NOW</button>
                        </div>
                    </Col>
                </Row>
            ) : (
                <>
                    <div className="w-100 mb-2">
                        <div className="step-progress-bar">
                            {steps.map((step, index) => (
                                <Col lg={3}
                                    key={index}
                                    className={`step mx-2 ${index <= currentStep ? 'active' : ''}`}
                                >
                                    {step}
                                </Col>
                            ))}
                        </div>

                    </div>
                    <Row className="w-100 mb-2">
                        <Col xs={12}>
                            <BootstrapProgressBar variant="warning" now={progress} className="custom-progress-bar" />
                        </Col>
                    </Row>
                    <Row className="w-100 justify-content-center align-items-center p-5">
                        {currentStep === 0 && (
                            <Col lg={6} md={6}>
                                <Form className="user-details-form" onSubmit={formik.handleSubmit}>
                                    {userDetailStep === 0 && (
                                        <Form.Group className="mb-3">
                                            <Form.Label>Name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="name"
                                                value={formik.values.name}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.name && formik.errors.name ? (
                                                <div className="text-danger">{formik.errors.name}</div>
                                            ) : null}
                                            <button className="mt-3 nxt-btn" type="button" onClick={() => handleNextUserDetailStep(0)}>
                                                Next
                                            </button>
                                        </Form.Group>
                                    )}
                                    {userDetailStep === 1 && (
                                        <Form.Group className="mb-3">
                                            <Form.Label>Mobile</Form.Label>
                                            <Form.Control
                                                type="tel"
                                                name="mobile"
                                                value={formik.values.mobile}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.mobile && formik.errors.mobile ? (
                                                <div className="text-danger">{formik.errors.mobile}</div>
                                            ) : null}
                                            <button className="mt-3 nxt-btn" type="button" onClick={() => handleNextUserDetailStep(1)}>
                                                Next
                                            </button>
                                        </Form.Group>
                                    )}
                                    {userDetailStep === 2 && (
                                        <Form.Group className="mb-3">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control
                                                type="email"
                                                name="email"
                                                value={formik.values.email}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.email && formik.errors.email ? (
                                                <div className="text-danger">{formik.errors.email}</div>
                                            ) : null}
                                            <button className="mt-3 nxt-btn" type="button" onClick={() => handleNextUserDetailStep(2)}>
                                                Submit
                                            </button>
                                        </Form.Group>
                                    )}
                                </Form>
                            </Col>
                        )}
                        {currentStep === 1 && (
                            <Col lg={6}>
                                <div className="question-card text-center">
                                    <QuestionCard
                                        question={questions[currentQuestionIndex]}
                                        currentAnswers={answers[currentQuestionIndex]}
                                        onSelectOption={handleSelectOption}
                                    />
                                    <button className='prev-btn' onClick={handlePrevQuestion} disabled={currentQuestionIndex === 0}>Previous</button>
                                    <button className="nxt-btn" onClick={handleNextQuestion} disabled={answers[currentQuestionIndex].length === 0}>Next</button>
                                </div>
                            </Col>
                        )}
                    </Row>
                </>
            )}
        </Container>
    );
};

const QuestionCard = ({ question, currentAnswers, onSelectOption }) => {
    return (
        <div className="question-content">
            <h5 className="mb-4 question-title">Question {question.id} / 6</h5>
            <h6 className="mb-4 text-bold">{question.id}{". "}{question.text}</h6>
            <Form>
                {question.options.map((option, index) => (
                    <div
                        key={index}
                        className={`option-check ${currentAnswers.includes(option) ? 'checked' : ''}`}
                        onClick={() => onSelectOption(option)}
                    >
                        <Form.Check
                            type="checkbox"
                            checked={currentAnswers.includes(option)}
                            readOnly
                        />
                        <Form.Check.Label>{option}</Form.Check.Label>
                    </div>
                ))}
            </Form>
        </div>
    );
};

export default TestPage;




// import React, { useState } from 'react';
// import { Button, Container, Form, ProgressBar as BootstrapProgressBar, Row, Col } from 'react-bootstrap';
// import './Testpage.css'; // Import custom styles
// import productImage from './thumbnailImage-1715175193186-383385148A2 K1 C1 T1.png'; // Import the image
// import { useNavigate } from 'react-router-dom';

// const TestPage = () => {
//     const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
//     const [answers, setAnswers] = useState(Array(6).fill([]));
//     const [showResult, setShowResult] = useState(false);

//     const navigate = useNavigate()

//     const [currentStep, setCurrentStep] = useState(0);
//     const [userDetailStep, setUserDetailStep] = useState(0);
//     const [userDetails, setUserDetails] = useState({ name: '', mobile: '', email: '' });


//     const questions = [
//         { id: 1, text: 'તમને હરસ મસા ,ભગંદર,ફિશર - ફીસ્ટુલા,વાઢીયા પેક્કી કઈ સમ્સીયા છે?', options: ['હરસ - મસા', 'ફિશર -ફીસ્ટુલા', 'ભગંદર', 'વાઢીયા', 'તમને જાણ નથી...'] },
//         { id: 2, text: 'તમને શું શું સમ્સીયા થાય છે?', options: ['બળતરા રહે છે', 'લોહી પડે છે', 'દુખાવો રહે છે', 'બેસી શકાતું નથી', 'મસા બહાર આવે છે', 'રસી પડે છે', 'રીંગ માં સોજો રહે છે', 'ખંજવાળ આવે છે', 'તમને જાન નથી'] },
//         { id: 3, text: 'કોઈ ડોક્ટર ને બતાવેલ છે?', options: ['હા', 'ના', 'ઘણા દવા કરાવી છતાં ફર્ક નથી'] },
//         { id: 4, text: 'કેટલા ટાઇમ થી આ સમસ્યા છે?', options: ['1 મહિના', '૨ મહિના', '3 મહીનાથીન વધારે', '1 વર્ષ કે તેનાથીન વધારે', 'તીખું ખવાય ત્યારે જ પ્રોબ્લેમ આવે છે'] },
//         { id: 5, text: 'તમને કબજિયાત રહે છે?', options: ['હા', 'ના', 'બહાર નું ખવાય ત્યારે જ', 'ક્યારેક ક્યારેક', 'મેંદા વાળું ખવાય ત્યારે'] },
//         { id: 6, text: 'કબજીયાત માં શું શું પ્રોબલેમ થાય છે?', options: ['વારંવાર ટોયલેટ જાવું પડે છે', 'ટોયલેટ કરતી વખતે દુખાવો થાય છે', 'ટોયલેટ માં બળ કરવું પડે કે લોહી પડે', 'પેટ ભારે લાગે છે', 'પેટ માં દુઉખવો થવા લાગે છે'] },
//     ];

//     const handleUserDetailChange = (e) => {
//         const { name, value } = e.target;
//         setUserDetails((prevDetails) => ({ ...prevDetails, [name]: value }));
//     };

//     const handleUserDetailNext = () => {
//         if (userDetailStep < 2) {
//             setUserDetailStep(userDetailStep + 1);
//         } else {
//             handleUserDetailsSubmit();
//         }
//     };

//     const handleUserDetailsSubmit = async () => {
//         // Send user details to the database
//         try {
//             // await axios.post('/api/user-details', userDetails);

//             setCurrentStep(currentStep + 1);
//         } catch (error) {
//             console.error('Error submitting user details:', error);
//         }
//     };

//     const handleNextQuestion = async () => {
//         if (currentQuestionIndex < questions.length - 1) {
//             setCurrentQuestionIndex(currentQuestionIndex + 1);
//         } else {
//             setShowResult(true);
//             // Send answers to the database
//             try {

//                 // await axios.post('/api/health-answers', { userDetails, answers });
//             } catch (error) {
//                 console.error('Error submitting health answers:', error);
//             }
//         }
//     };

//     const handlePrevQuestion = () => {
//         if (currentQuestionIndex > 0) {
//             setCurrentQuestionIndex(currentQuestionIndex - 1);
//         }
//     };

//     const handleSelectOption = (option) => {
//         const updatedAnswers = [...answers];
//         const currentAnswers = updatedAnswers[currentQuestionIndex];
//         if (currentAnswers.includes(option)) {
//             updatedAnswers[currentQuestionIndex] = currentAnswers.filter((o) => o !== option);
//         } else {
//             updatedAnswers[currentQuestionIndex] = [...currentAnswers, option];
//         }
//         setAnswers(updatedAnswers);
//     };

//     const progress = ((currentQuestionIndex + 1) / questions.length) * 100;

//     return (
//         <Container className="my-4 d-flex flex-column align-items-center">
//             {showResult ? (
//                 <Row className="text-center align-items-center justify-content-between">
//                     <h5 className='mb-4 question-title'>Test Completed!</h5>
//                     <h5 className='mb-4'>Treatment Kit</h5>
//                     <Col lg={4} md={6}>
//                         <div>
//                             <img src={productImage} alt="Product" className="result-image" />
//                         </div>
//                         <h5>૨ અર્શિત સીરપ + ૧ SBLX ચૂર્ણ + ૧ અર્શિત કેપ્સ્યુલ +૧ અર્શિત ટ્યુબ</h5>
//                     </Col>
//                     <Col lg={7} md={6}>
//                         <div className="result-text">
//                             <h5 className='mb-3'>આખી કીટ ઉપયોગ કરવાનું કારણ?</h5>
//                             <ul className='mb-3'>
//                                 <li>અર્શિત ની જે સીરપ આવે છે તે શરીરની અંદરની ગરમી તથા આતરની ગરમીને બહાર કાઢવામાં મદદરૂપ કરે છે
//                                     તથા તેની અંદર આવતી જડીબુટ્ટી નિરંજન ફળ હરસ મસા ભગંદર ફિશર ફેસ્ટુલા જેવા મળમાર્ગ ના બધા જ રોગોની
//                                     અંદર રામબાણ ઔષધી છે આ કીટમાં તમને અર્શિત ટ્યુબ પણ મળશે જે મળ માર્ગની અંદર ખંજવાળ તથા
//                                     ઇન્ફેક્શનને ફેલતા અટકાવે છે આની સાથે SBLX કબજિયાત ચૂર્ણ પણ આવશે જે શરીરના પાચનતંત્ર અને
//                                     મજબૂત કરશે અને જો શરીરમાં કબજિયાત રહેતી હોય તો ટેવ પાડ્યા વગર તેને દૂર કરશે સાથે આ કીટમાં
//                                     અર્શિત કેપ્સુલ પણ નાખવામાં આવી છે જે મસા ને સૂકવવામાં મદદ કરે છે
//                                 </li>
//                             </ul>
//                             <h5 className='mb-3'>અર્શિત કીટ લેવાના ફાયદા?</h5>
//                             <ul className='mb-3'>
//                                 <li>આર્શિત કીટ એક આયુર્વેદિક ઉપચાર છે જે હરસ મસા માટે 100% અસરકારક ઉપાય છે
//                                     પરંતુ આ મેડિસિન ને અસર કરવામાં અમુક સમય ગાળો લાગે છે શરૂઆતના દસથી પંદર
//                                     દિવસમાં આર્શિતના રેગ્યુલર ઉપયોગથી દુખાવો અને બળતરા જેવી સમસ્યામાં રાહત મળે છે
//                                     પરંતુ જે મસા બહાર આવેલા હોય તથા રિંગમાં સોજો આવતો હોય તથા હરસ મસા ફિશર
//                                     ફિસ્તુલા ને લગતી કોઈપણ સમસ્યા હોય તો તેને સરખી થવા માં નોર્મલી ત્રણ મહિના જેટલો કોર્સ કરવો ફરજીયાત છે
//                                     આર્શિત દવામાં આયુર્વેદિક ઔષધીઓ જેવીકે નિરંજન ફળ, દારૂ અરીઠા, અરીઠા છાલ નાખવામાં આવે છે
//                                     જે હરસ મસા ભગંદર ફીશર ફિશતુલા જેવા રોગોમાં રામબાણ રીતે ઔષધિ નું કામ કરે છે બાકી તમે
//                                     આના વિશે વધારે માહિતી નીચે આપેલા નંબર અથવા તો કસ્ટમર કેર નંબર માં કોલ કરીને નિષ્ણાતો
//                                     સાથે વાત કરી વધારે માહિતી મેળવી શકો છો
//                                 </li>
//                             </ul>
//                         </div>
//                         <button className="buy-now-btn" onClick={() => navigate("/product")}>Buy Now</button>
//                     </Col>
//                 </Row>
//             ) : (currentStep === 0 ? (
// <Form className="user-details-form w-50">
//     <h3 className="mb-4">About You</h3>
//     {userDetailStep === 0 && (
//         <Form.Group className="mb-3">
//             <Form.Label>Name</Form.Label>
//             <Form.Control
//                 type="text"
//                 name="name"
//                 value={userDetails.name}
//                 onChange={handleUserDetailChange}
//             />
//             <button className="mt-3 nxt-btn" onClick={handleUserDetailNext}>
//                 Next
//             </button>
//         </Form.Group>
//     )}
//     {userDetailStep === 1 && (
//         <Form.Group className="mb-3">
//             <Form.Label>Mobile</Form.Label>
//             <Form.Control
//                 type="text"
//                 name="mobile"
//                 value={userDetails.mobile}
//                 onChange={handleUserDetailChange}
//             />
//             <button className="mt-3 nxt-btn" onClick={handleUserDetailNext}>
//                 Next
//             </button>
//         </Form.Group>
//     )}
//     {userDetailStep === 2 && (
//         <Form.Group className="mb-3">
//             <Form.Label>Email</Form.Label>
//             <Form.Control
//                 type="email"
//                 name="email"
//                 value={userDetails.email}
//                 onChange={handleUserDetailChange}
//             />
//             <button className="mt-3 nxt-btn" onClick={handleUserDetailNext}>
//                 Submit
//             </button>
//         </Form.Group>
//     )}
// </Form>
//             ) : (
//                 <>
//                     <Row className="w-100 justify-content-center mb-2">

//                         <Col xs={12}>
//                             <BootstrapProgressBar variant="warning" now={progress} className="custom-progress-bar" />
//                         </Col>
//                         {/* <Col xs={1}></Col> */}
//                     </Row>
//                     <Row className="w-100 mb-2 justify-content-start">
//                         <Col xs={1}>
//                             <button className='prev-btn' onClick={handlePrevQuestion} disabled={currentQuestionIndex === 0}>Previous</button>
//                         </Col>

//                     </Row>
//                     <div className="question-card text-center">
//                         <QuestionCard
//                             question={questions[currentQuestionIndex]}
//                             currentAnswers={answers[currentQuestionIndex]}
//                             onSelectOption={handleSelectOption}
//                         />
//                         <button className="mt-4 nxt-btn" onClick={handleNextQuestion} disabled={answers[currentQuestionIndex].length === 0}>Next Question</button>
//                     </div>
//                 </>
//             )
//             )}
//         </Container>
//     );
// };

// const QuestionCard = ({ question, currentAnswers, onSelectOption }) => {
//     return (
//         <div className="question-content">
//             <h5 className="mb-4 question-title">Question {question.id} / 6</h5>
//             <h6 className="mb-4 text-bold">{question.id}{". "}{question.text}</h6>
//             <Form>
//                 {question.options.map((option, index) => (
//                     <div
//                         key={index}
//                         className={`option-check ${currentAnswers.includes(option) ? 'checked' : ''}`}
//                         onClick={() => onSelectOption(option)}
//                     >
//                         <Form.Check
//                             type="checkbox"
//                             checked={currentAnswers.includes(option)}
//                             readOnly
//                         />
//                         <Form.Check.Label>{option}</Form.Check.Label>
//                     </div>
//                 ))}
//             </Form>
//         </div>
//     );
// };

// export default TestPage;



// import React from 'react'
// import { Button, Col, Container, Row } from 'react-bootstrap'
// import { useNavigate } from 'react-router-dom'




// export default function CommingSoon() {

//     const navigate = useNavigate()
//     const handelBackToHome = () => {
//         navigate('/')
//     }



//     return (
//         <>
//             <div className='py-5'>
//                 <Container>
//                     <Row className='align-items-center justify-content-center mb-5'>
//                         <Col lg={6} className='text-center'>
//                             <h4>Comming Soon.....</h4>
//                         </Col>
//                     </Row>
//                     <Row className='align-items-center justify-content-center'>
//                         <Col lg={6} className='text-center'>
//                             <Button className='btn order-btn' onClick={handelBackToHome}>Back To Home</Button>
//                             {/* <Button className='btn order-btn' onClick={handlePayment}>Payment</Button> */}
//                         </Col>
//                     </Row>
//                 </Container>
//             </div>
//         </>
//     )
// }

