import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getReviewVideo } from "../../features/Video/VideoSlice";
import Slider from 'react-slick'
import { Col, Container, Modal, Row } from "react-bootstrap";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { AiOutlineArrowRight } from "react-icons/ai";

import "./videoslider.css"

const imgUrl = process.env.REACT_APP_BASE_URL_IMAGE;


function SamplePrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div onClick={onClick} className={`arrow ${className}`} >
      <AiOutlineArrowLeft className="arrows" />
    </div>
  )
}

function SampleNextArrow(props) {
  const { className, onClick } = props;
  return (
    <div onClick={onClick} className={`arrow ${className}`} >
      <AiOutlineArrowRight className="arrows" />
    </div>
  )
}

const Videobg = () => {

  const [showModal, setShowModal] = useState(false);
  const [currentVideoUrl, setCurrentVideoUrl] = useState('');

  const dispatch = useDispatch()

  const { reviewVideos } = useSelector((state) => state.video)

  useEffect(() => {
    dispatch(getReviewVideo())
  }, [dispatch]);

  const handleVideoClick = (video) => {
    setCurrentVideoUrl(video);

    setShowModal(true);

  };


  let settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 6000,
    nextArrow: <SampleNextArrow to="next" />,
    prevArrow: <SamplePrevArrow to="prev" />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };



  return (

    <div className="advertise-video">
      <Container className="">
        <Row className="justify-content-center align-item-center">
          <Col lg={6} className="text-center">
            <h6 className="product-tab-title mb-1">About Product</h6>
            <div className="bar"></div>
          </Col>
        </Row>
        <Row className="justify-content-center align-items-center position-relative">
          <Col lg={10} md={10} sm={10} className="advideo-area">
            <Slider {...settings}>
              {reviewVideos?.map((ad, index) => {
                return (
                  <div className="px-2" key={index} >
                    <div className="rounded overflow-hidden">
                      <div className="ratio ratio-16x9">
                        <img src={imgUrl + ad?.reviewVideoThumbnail} className="w-100 h-100 object-fit-cover" alt="" />
                        <div className="video-play">
                          <span onClick={() => handleVideoClick(ad)} className="popup-video" title="play">
                            <i className="fas fa-play"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                    <h5 className="mb-0 mb-1 mt-1">{ad?.reviewVideoTitle}</h5>
                    <p>{ad?.reviewVideoDescription}</p>
                  </div>
                );
              })}
            </Slider>
          </Col>
        </Row>
      </Container>
      <VideoModal
        show={showModal}
        onHide={() => setShowModal(false)}
        video={currentVideoUrl}

      />
    </div>


  );
};

export default Videobg;


const VideoModal = ({ show, onHide, video }) => {

  if (!video) return null; // Handle case when video is not selected

  return (
    <Modal show={show} onHide={onHide} size="xl" centered className="advideo-area">
      <Modal.Header closeButton className="p-0">
        {/* <Modal.Title>{video.reviewVideoTitle}</Modal.Title> */}
      </Modal.Header>
      <Modal.Body className="p-0">
        <div className="rounded overflow-hidden">
          <div className="position-relative ratio ratio-16x9">
            <video
              className="position-absolute w-100 h-100 object-fit-cover"
              controls
              controlsList="nodownload"
            >
              <source src={imgUrl + video.reviewVideoUrl} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
        {/* <p>{video.advertisVideoDescription}</p> */}
      </Modal.Body>
    </Modal>
  );
};
