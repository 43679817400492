import React from 'react'

import Singleabout from './About/Singleabout'


const About = () => {
  return (
    <div>
      
      <Singleabout/>
    </div>
  )
}

export default About
