import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import testUserService from './testUserService';

// Async thunk for adding a new test user
export const addNewTestUser = createAsyncThunk(
  'testUsers/addNewTestUser',
  async ({ userDetails, answers }, { rejectWithValue }) => {
   
    try {
      return await testUserService.addNewTestUser(userDetails, answers);

    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Async thunk for retrieving the suggested product
export const getSuggestedProduct = createAsyncThunk(
  'testUsers/getSuggestedProduct',
  async (_, { rejectWithValue }) => {
    try {
      return await testUserService.getSuggestedProduct();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);


// Define the initial state for test users
const initialState = {
  isLoading: false,
  isSuccess: false,
  isError: null,
  suggestedProduct: {},
};

// Create a test user slice
const testUserSlice = createSlice({
  name: 'testUsers',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Reducer for addNewTestUser
    builder.addCase(addNewTestUser.pending, (state) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.isError = null;
    });
    builder.addCase(addNewTestUser.fulfilled, (state) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isError = null;
    });
    builder.addCase(addNewTestUser.rejected, (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = action.payload.message;
    });
    // Reducers for getSuggestedProduct
    builder.addCase(getSuggestedProduct.pending, (state) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.isError = null;
    });
    builder.addCase(getSuggestedProduct.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isError = null;
      state.suggestedProduct = action.payload.suggestedProduct;
    });
    builder.addCase(getSuggestedProduct.rejected, (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = action.payload.message;
    });

  }
});

export default testUserSlice.reducer;

