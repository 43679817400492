import React, { useEffect, useState } from 'react'
import reginbanner from "./img/Fill out-pana.svg"
import { Col, Container, Row } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik';
import * as yup from 'yup';
import "./Login.css"
import { useDispatch, useSelector } from 'react-redux';
import { register, loginbyOTP, resendOTP, sendOTP, verifyOTP } from '../../../features/auth/authSlice';

export default function Register() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoggedIn, isVerified, isRegistered } = useSelector((state) => state.auth);
  const [showOTPField, setShowOTPField] = useState(false);
  const [formValues, setFormValues] = useState({});

  let schemaWithoutOTP = yup.object().shape({
    phoneNumber: yup.string().max(10, "Mobile Number must be exactly 10 digits")
    .matches(/^\d+$/, "Mobile Number must be a valid number")
    .required("Mobile Number is Required"),
    username: yup.string().required("Username is Required"),
    email: yup.string().email("Email should be valid and must contain '@'").email("Email should be valid and must contain '.'")
    .required("Email is Required"),
    password: yup.string().required("No password provided.")
  });

  let schemaWithOTP = schemaWithoutOTP.shape({
    otp: yup.string().required("OTP is Required")
  });


  const formik = useFormik({
    initialValues: {
      phoneNumber: "",
      username: "",
      email: "",
      password: "",
      role: "user",
      otp: ""
    },
    validationSchema: showOTPField ? schemaWithOTP : schemaWithoutOTP,
    onSubmit: async (values) => {
      setFormValues(values);
      try {
        if (!showOTPField) {
          await dispatch(sendOTP(values.phoneNumber));
          setShowOTPField(true);
        } else {
          await dispatch(verifyOTP({ phoneNumber: values.phoneNumber, otp: values.otp }));
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
  });

  useEffect(() => {
    if (isVerified) {
      dispatch(register(formValues))
    }
  }, [isVerified, dispatch]);

  useEffect(() => {
    if (isRegistered) {

      dispatch(loginbyOTP({ phoneNumber: formValues.phoneNumber, otp: formValues.otp }));
      formik.resetForm()
    }
  }, [isRegistered, dispatch]);

  useEffect(() => {
    if (isRegistered && isLoggedIn) {
    
      navigate("/");
    }
  }, [isLoggedIn, isRegistered, navigate]);

  const handleResendOTP = () => {
    dispatch(resendOTP(formik.values.phoneNumber)).then(() => {
      formik.setFieldValue('otp', '');

    });
  };

  const handlePhoneNumberChange = (event) => {
    const { value } = event.target;
    // Allow only numbers and limit to 10 digits
    const formattedValue = value.replace(/\D/g, '').slice(0, 10);
    formik.setFieldValue('phoneNumber', formattedValue);
  };

  return (
    <>
      <div className="reg-page m-5 py-4">
        <Container className="g-0">
          <Row className="align-items-center justify-content-center">
            <Col lg={6} md={6} className="login-left">
              <img src={reginbanner} className="img-fluid" alt="loginbanner" />
            </Col>
            <Col lg={5} md={6} className="login-right">
              <div className="login-header">
                <h5>
                  Register to <span>Wincenzo</span>
                </h5>
              </div>

              <form onSubmit={formik.handleSubmit} className="mx-auto">
                <div className="mb-3">
                  <label className="focus-label">Username</label>
                  <input
                    type="text"
                    name="username"
                    onChange={formik.handleChange("username")}
                    value={formik.values.username}
                    className="form-control floating"
                  />
                  <div className="text-danger err-text">
                    {formik.touched.username && formik.errors.username}
                  </div>
                </div>

                <div className="mb-3">
                  <label className="focus-label">Mobile Number</label>
                  <input
                    type="text"
                    name="phoneNumber"
                    onChange={handlePhoneNumberChange}
                    value={formik.values.phoneNumber}
                    className="form-control floating"
                  />
                  <div className="text-danger err-text">
                    {formik.touched.phoneNumber && formik.errors.phoneNumber}
                  </div>
                </div>

                <div className="mb-3">
                  <label className="focus-label">Email</label>
                  <input
                    type="email"
                    name="email"
                    onChange={formik.handleChange("email")}
                    value={formik.values.email}
                    className="form-control floating"
                  />
                  <div className="text-danger err-text">
                    {formik.touched.email && formik.errors.email}
                  </div>
                </div>
                <div className="mb-3">
                  <label className="focus-label">Password</label>
                  <input
                    name="password"
                    type="Password"
                    onChange={formik.handleChange("password")}
                    value={formik.values.password}
                    className="form-control floating"
                  />
                  <div className="text-danger err-text">
                    {formik.touched.password && formik.errors.password}
                  </div>
                </div>

                {showOTPField && (
                  <div className="mb-3">
                    <label className="focus-label">Enter OTP</label>
                    <input
                      name="otp"
                      type="text"
                      onChange={formik.handleChange("otp")}
                      value={formik.values.otp}
                      className="form-control floating"
                    />
                    <div className="text-danger err-text">
                      {formik.touched.otp && formik.errors.otp}
                    </div>
                  </div>
                )}

                <div className="mb-3 d-flex gap-2">
                  <button className={`btn btn-lg ${showOTPField ? 'col-6' : 'col-12'} register-btn mb-3`} type="submit">
                    {showOTPField ? "Verify OTP & Register" : "Register"}
                  </button>

                  {showOTPField && (
                    <button
                      className={`btn btn-lg ${showOTPField ? 'col-6' : 'col-12'} register-btn mb-3`}
                      type="button"
                      onClick={handleResendOTP}
                    >
                      Resend OTP
                    </button>
                  )}
                </div>

                {/* <button className="btn btn-lg register-btn mb-3" type="submit">
                  {showOTPField ? "Verify OTP & Register" : "Register"}
                </button> */}

                <div className="dont-have">
                  Already have an account?{" "}
                  <Link className="forgot-link" to="/login">
                    Login
                  </Link>{" "}
                </div>
              </form>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
