import axios from "axios";
import { baseUrl } from "../../configs/baseUrl";
import { getConfig } from "../../configs/axiosConfig";
// const baseUrl = process.env.REACT_APP_BASE_URL;
const login = async (userData) => {
  const res = await axios.post(`${baseUrl}users/login`, userData);
  if (res.data) {
    localStorage.setItem("USER", JSON.stringify(res.data?.user));
    localStorage.setItem("TOKEN", JSON.stringify(res.data?.token));
  }
  return res.data;
};


const reg = async (userData) => {
  const res = await axios.post(`${baseUrl}users`, userData);
  return res.data;
};



const out = () => {
  localStorage.removeItem("USER");
};

const loginotp = async (DATA) => {
  const res = await axios.post(`${baseUrl}users/check-verification`, DATA);
  if (res.data) {
    localStorage.setItem("USER", JSON.stringify(res.data?.unverifiedUser));
    localStorage.setItem("TOKEN", JSON.stringify(res.data?.token));
  }
  return res.data;
};
const getAuser = async (DATA) => {
  const config = getConfig();
  const res = await axios.get(`${baseUrl}users/${DATA}`, config);

  return res.data;
};
const updateUser = async (DATA) => {
  const config = getConfig();
  const res = await axios.put(
    `${baseUrl}users/${DATA?.id}`,
    DATA?.formData,
    config
  );

  localStorage.removeItem("USER");

  return res.data;
};

const forgotPassword = async (DATA) => {
  const res = await axios.post(`${baseUrl}users/forgot-password-token`, DATA);

  return res.data;
};
const resetPassword = async (DATA) => {
  const res = await axios.put(
    `${baseUrl}users/reset-password/${DATA.token}`,
    DATA
  );

  return res.data;
};
const allOrders = async (DATA) => {
  const config = getConfig();
  const res = await axios.get(`${baseUrl}orders/user/${DATA}`, config);

  return res.data;

};
const addOrders = async (DATA) => {
  const config = getConfig();
  const res = await axios.post(`${baseUrl}orders`, DATA, config);

  if (res.data?.order && res.data?.order?.user) {
    // Update the user data in local storage
    localStorage.setItem("USER", JSON.stringify(res.data?.order?.user));
  }
  return res.data;
};

export const sendOrderEmail = async (orderDetails) => {
  try {
    const response = await axios.post(`${baseUrl}orders/send-order-email`, { orderDetails });
    return response.data;
  } catch (error) {
    throw new Error('Error sending email: ' + error.message);
  }
};

 const getToken = async () => {
  const response = await axios.get(`${baseUrl}shiprocket/gettoken`);
  return response;
};





const getwishlist = async (data) => {
  const config = getConfig();
  const res = await axios.get(`${baseUrl}wishlist/${data}`, config);
  return res.data;
};
const addwishlist = async (data) => {
  const config = getConfig();
  const res = await axios.post(`${baseUrl}wishlist`, data, config);
  return res.data;
};

const deletewishlist = async (data) => {
  const config = getConfig();
  const res = await axios.delete(`${baseUrl}wishlist/${data}`, config);
  return res.data;
};

//#############################################################################//
//###############  Regiser and login by OTP (Third Party Integration)##########################//

const sendOTP = async (phoneNumber) => {
  const response = await axios.post(`${baseUrl}otp/send-otp`, { phoneNumber });
  return response.data;
};

const verifyOTP = async ({ phoneNumber, otp }) => {
 
  const response = await axios.post(`${baseUrl}otp/verify-otp`, { phoneNumber, otp });
  return response.data;

};

const loginByOtp = async ({ phoneNumber, otp }) => {
  const res = await axios.post(`${baseUrl}otp/loginby-otp`, { phoneNumber, otp });
  if (res.data) {
    localStorage.setItem("USER", JSON.stringify(res.data?.user));
    localStorage.setItem("TOKEN", JSON.stringify(res.data?.token));
  }
  return res.data;

}

const resendOTP = async (phoneNumber) => {

  const response = await axios.post(`${baseUrl}otp/resend-otp`, { phoneNumber });
  return response.data;

};


const authService = {
  login,
  reg,
  out,
  loginotp,
  getAuser,
  updateUser,
  forgotPassword,
  resetPassword,
  allOrders,
  getToken,
  addOrders,
  deletewishlist,
  addwishlist,
  sendOrderEmail,
  getwishlist,

  //###############  Regiser and login by OTP (Third Party Integration)##########################//

  sendOTP,
  verifyOTP,
  resendOTP,
  loginByOtp,
};

export default authService;
