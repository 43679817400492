import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { trackOrderByAWB } from '../../../features/trackorderReducer/trackorderSlice';  // Import the thunk action
import './TrackOrder.css';  // External CSS file

const FormWizard = () => {
  const [awb, setAwb] = useState('');
  const dispatch = useDispatch();

  const trackingDataState = useSelector((state) => state.trackingData);
  const trackingData = trackingDataState?.trackingData || {};
  const { tracking_data = {}, loading, error } = trackingData;



  const edd = tracking_data?.shipment_track?.[0]?.edd;


  // Handle form submission to track order
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(trackOrderByAWB(awb));
  };


  // Function to format the EDD properly
  const formatDate = (dateStr) => {
    if (!dateStr) return "EDD not available";  // Handle empty or invalid dates

    const parsedDate = new Date(dateStr);
    if (isNaN(parsedDate)) return "Invalid Date";  // Check if parsed date is valid

    // Format date to "Day Name, Day Month Year"
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return parsedDate.toLocaleDateString('india', options);
  };



  // Filter the tracking activities to extract relevant information (date, activity, location)
  const filteredActivities = tracking_data?.shipment_track_activities?.map(activity => ({
    date: activity.date,
    activity: activity['sr-status-label'],
    location: activity.location
  })) || [];

  return (
    <div className="form-wizard-container">
      <div className="form-container">
        <h2 className="form-title">Enter Your AWB Number</h2>
        <form onSubmit={handleSubmit} className="track-order-form">
          <div className="form-group">
            <label htmlFor="awb" className="awb-label">AWB Number</label>
            <input
              id="awb"
              type="text"
              placeholder="Enter your AWB number"
              value={awb}
              onChange={(e) => setAwb(e.target.value)}
              className="form-control"
            />
          </div>
          <button
            type="submit"
            className="btn btn-dark"
            disabled={loading}
          >
            {loading ? "Tracking..." : "Track Order"}
          </button>
        </form>

        {loading && <p>Loading...</p>}
        {error && <p className="error">Error: {error}</p>}

        {filteredActivities.length > 0 && (
          <div className="tracking-result">
            <h3>Tracking Information  <div class="tracking-info">
  <p class="estimated-delivery">
    Estimated Delivery Date: <span class="edd-highlight"> {formatDate(edd)}</span> 
      </p>
</div></h3>
            <div className="timeline-container">
              {filteredActivities.map((activity, index) => (
                <div key={index} className="timeline-item">
                  <div className="timeline-date">
                    {new Date(activity.date).toLocaleDateString()} - {new Date(activity.date).toLocaleTimeString()}
                  </div>
                  <div className="timeline-content">
                    <p><strong>Activity:</strong> {activity.activity}</p>
                    <p><strong>Location:</strong> {activity.location}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FormWizard;











// import React, { useState, useEffect } from "react";
// import { useForm } from "react-hook-form";
// import { yupResolver } from "@hookform/resolvers/yup";
// import * as yup from "yup";
// import "./TrackOrder.css";  // Linking external CSS

// const steps = [
//   {
//     id: 1,
//     title: "Account Details",
//   },
//   {
//     id: 2,
//     title: "Personal info",
//   },
//   {
//     id: 3,
//     title: "Address",
//   },
//   {
//     id: 4,
//     title: "Social Links",
//   },
//   {
//     id: 5,
//     title: "Links",
//   },
//   {
//     id: 6,
//     title: "Links",
//   },
// ];

// let stepSchema = yup.object().shape({
//   username: yup.string().required("User name is required"),
//   fullname: yup.string().required("Full name is required"),
//   email: yup.string().email("Email is not valid").required("Email is required"),
//   phone: yup
//     .string()
//     .required("Phone number is required")
//     .matches(/^[0-9]{12}$/, "Phone number is not valid"),
//   password: yup
//     .string()
//     .required("Password is required")
//     .min(8, "Password must be at least 8 characters"),
//   confirmpass: yup
//     .string()
//     .required("Confirm Password is required")
//     .oneOf([yup.ref("password"), null], "Passwords must match"),
// });

// let personalSchema = yup.object().shape({
//   fname: yup.string().required("First name is required"),
//   lname: yup.string().required("Last name is required"),
// });

// let addressSchema = yup.object().shape({
//   address: yup.string().required("Address is required"),
// });

// const url =
//   /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;

// let socialSchema = yup.object().shape({
//   fburl: yup
//     .string()
//     .required("Facebook URL is required")
//     .matches(url, "Facebook URL is not valid"),
// });

// const FormWizard = () => {
//   const [stepNumber, setStepNumber] = useState(0);

//   // Find the current step schema
//   let currentStepSchema;
//   switch (stepNumber) {
//     case 0:
//       currentStepSchema = stepSchema;
//       break;
//     case 1:
//       currentStepSchema = personalSchema;
//       break;
//     case 2:
//       currentStepSchema = addressSchema;
//       break;
//     case 3:
//       currentStepSchema = socialSchema;
//       break;
//     case 4:
//       currentStepSchema = stepSchema; // You may need another schema here
//       break;
//     default:
//       currentStepSchema = stepSchema;
//   }

//   useEffect(() => {}, [stepNumber]);

//   const {
//     register,
//     formState: { errors },
//     handleSubmit,
//   } = useForm({
//     resolver: yupResolver(currentStepSchema),
//     mode: "all",
//   });

//   const onSubmit = (data) => {
//     const totalSteps = steps.length;
//     const isLastStep = stepNumber === totalSteps - 1;
//     if (isLastStep) {
//       console.log(data);
//     } else {
//       setStepNumber(stepNumber + 1);
//     }
//   };

//   const handlePrev = () => {
//     setStepNumber(stepNumber - 1);
//   };

//   return (
//     <div className="form-wizard-container">
//       <div className="form-card">
//         <div>
//           <div className="steps-container">
//             {steps.map((item, i) => (
//               <div
//                 className={`step-item ${stepNumber >= i ? "active-step" : ""}`}
//                 key={i}
//               >
//                 <div className={`step-icon ${stepNumber <= i ? "" : "completed"}`}>
//                   {stepNumber <= i ? (
//                     <span>{i + 1}</span>
//                   ) : (
//                     <span>&#10003;</span> // Use a checkmark here for completed steps
//                   )}
//                 </div>
//                 <div className="step-title">{item.title}</div>
//               </div>
//             ))}
//           </div>

//           <div className="content-box">
//             <form onSubmit={handleSubmit(onSubmit)}>
//               {stepNumber === 0 && (
//                 <div className="form-step-content">
//                   <h4 className="form-section-title">Enter Your Account Details</h4>
//                   <input
//                     label="Username"
//                     type="text"
//                     placeholder="Type your User Name"
//                     name="username"
//                     {...register('username')}
//                   />
//                   <p>{errors.username?.message}</p>
//                   <input
//                     label="Full name"
//                     type="text"
//                     placeholder="Full name"
//                     name="fullname"
//                     {...register('fullname')}
//                   />
//                   <p>{errors.fullname?.message}</p>
//                   <input
//                     label="Email"
//                     type="email"
//                     placeholder="Type your email"
//                     name="email"
//                     {...register('email')}
//                   />
//                   <p>{errors.email?.message}</p>
//                   <input
//                     label="Phone Number"
//                     type="text"
//                     placeholder="Phone Number"
//                     name="phone"
//                     {...register('phone')}
//                   />
//                   <p>{errors.phone?.message}</p>
//                   <input
//                     label="Password"
//                     type="password"
//                     placeholder="8+ characters, 1 capital letter"
//                     name="password"
//                     {...register('password')}
//                   />
//                   <p>{errors.password?.message}</p>
//                   <input
//                     label="Confirm Password"
//                     type="password"
//                     placeholder="Password"
//                     name="confirmpass"
//                     {...register('confirmpass')}
//                   />
//                   <p>{errors.confirmpass?.message}</p>
//                 </div>
//               )}

//               {stepNumber === 1 && (
//                 <div className="form-step-content">
//                   <h4 className="form-section-title">Enter Your Personal Info</h4>
//                   <input
//                     label="First name"
//                     type="text"
//                     placeholder="First name"
//                     name="fname"
//                     {...register('fname')}
//                   />
//                   <p>{errors.fname?.message}</p>
//                   <input
//                     label="Last name"
//                     type="text"
//                     placeholder="Last name"
//                     name="lname"
//                     {...register('lname')}
//                   />
//                   <p>{errors.lname?.message}</p>
//                 </div>
//               )}

//               {stepNumber === 2 && (
//                 <div className="form-step-content">
//                   <h4 className="form-section-title">Enter Your Address</h4>
//                   <textarea
//                     label="Address"
//                     placeholder="Write Address"
//                     name="address"
//                     {...register('address')}
//                   />
//                   <p>{errors.address?.message}</p>
//                 </div>
//               )}

//               {stepNumber === 3 && (
//                 <div className="form-step-content">
//                   <h4 className="form-section-title">Enter Your Social Links</h4>
//                   <input
//                     label="Facebook"
//                     type="text"
//                     placeholder="https://www.facebook.com/profile"
//                     name="fburl"
//                     {...register('fburl')}
//                   />
//                   <p>{errors.fburl?.message}</p>
//                 </div>
//               )}

//               <div className={`form-nav-buttons ${stepNumber > 0 ? "has-prev" : ""}`}>
//                 {stepNumber !== 0 && (
//                   <button type="button" className="btn-prev" onClick={handlePrev}>
//                     Prev
//                   </button>
//                 )}
//                 <button type="submit" className="btn-next">
//                   {stepNumber !== steps.length - 1 ? "Next" : "Submit"}
//                 </button>
//               </div>
//             </form>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default FormWizard;
