import axios from "axios";
import { baseUrl } from "../../configs/baseUrl";
import { config } from "../../configs/axiosConfig";

const addNewTestUser = async (userDetails, answers) => {
   
    const res = await axios.post(`${baseUrl}testuser/new-test-user`, { userDetails, answers });
    return res.data;
};
// Function to retrieve the suggested product
const getSuggestedProduct = async () => {
    const res = await axios.get(`${baseUrl}testuser/get-suggested-product`);
    return res.data;
};


const testUserService = {
    addNewTestUser,
    getSuggestedProduct,
 
}


export default testUserService;