import React, { useEffect } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./SingleAbout.css";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getAbout } from "../../features/cms/pageSlice";
import BreadCrumb from "../../components/BreadCrumb/BreadCrumb";

function Singleabout() {

  const dispatch = useDispatch();
  const { about } = useSelector((state) => state.page);
  const imgUrl = process.env.REACT_APP_BASE_URL_IMAGE;


  useEffect(() => {
    dispatch(getAbout());
  }, [dispatch]);

  let certificates = about?.certificate


  return (
    <>
      <BreadCrumb heading={"About Us"} location={"About"} />

      <Container>
        <Row className="justify-content-center align-item-center pt-5">
          <Col lg={6} className="certi-text text-center">
            <h3 className="product-tab-title mb-1">OUR CERTIFICATES</h3>
            <div className="bar"></div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="align-item-center justify-content-center flex-wrap">
          {certificates?.map((certificate, index) => (
            <Col lg={3} md={6} sm={6} key={index} className="mx-auto">
              <Card className="d-flex flex-column border-0 h-100">
                <Card.Img variant="top" src={imgUrl + certificate?.certificateImage} />
                <Card.Body className="d-flex flex-column justify-content-center">
                  <Card.Title className="text-center fs-5 fw-bold text-capitalize mb-0">{certificate.certificateTitle}</Card.Title>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
      <div className="productpage">
        <div className="container">
          <div className="row justify-content-center">
            <Col lg={9} md={8} className="p-4">
              <div className="sec-title text-center mb-3">
                <h2 className="sec-head-text">
                  About Us - Wincenzo Herbal Cure Company
                </h2>
                <p className="sec-sub-text">
                  Welcome to Wincenzo Herbal Cure,
                  where tradition meets innovation in the realm of Ayurvedic wellness.
                  Founded with a steadfast commitment to purity and efficacy,
                  Wincenzo Herbal Cure is proud to be a beacon of trust and quality in the herbal remedies industry.
                </p>
              </div>
            </Col>
            <Col lg={9} md={8} className="p-4">
              <div className="sec-title text-center mb-3">
                <h2 className="sec-head-text">
                  Our Commitment to Quality
                </h2>
                <p className="sec-sub-text">
                  At Wincenzo Herbal Cure, quality is our cornerstone.
                  We adhere to the highest standards of manufacturing practices,
                  proudly holding ISO and GMP certifications.
                  These credentials underscore our dedication to producing Ayurvedic products that meet global benchmarks for safety, purity, and effectiveness.
                </p>
              </div>
            </Col>
            <Col lg={9} md={8} className="p-4">
              <div className="sec-title text-center mb-3">
                <h2 className="sec-head-text">
                  The Essence of Ayurveda
                </h2>
                <p className="sec-sub-text">
                  Ayurveda, the ancient Indian science of healing, forms the heart of our formulations.
                  We harness the power of Ayurvedic extracts sourced from nature’s bounty to create remedies that rejuvenate and restore.
                  Each product is crafted with meticulous care and attention,
                  ensuring it embodies the essence of traditional wisdom while integrating modern research and technology.
                </p>
              </div>
            </Col>
            <Col lg={9} md={8} className="p-4">
              <div className="sec-title text-center mb-3">
                <h2 className="sec-head-text">
                  Our Product Range
                </h2>
                <p className="sec-sub-text">
                  Explore our diverse range of Ayurvedic products, meticulously formulated to promote holistic wellness.
                  From herbal supplements that support vitality and immunity to skincare solutions that enhance radiance,
                  each offering reflects our unwavering commitment to excellence.
                </p>
              </div>
            </Col>
            <Col lg={9} md={8} className="p-4">
              <div className="sec-title text-center mb-3">
                <h2 className="sec-head-text">
                  Ethical Practices and Sustainability
                </h2>
                <p className="sec-sub-text">
                  Beyond quality, we prioritize sustainability and ethical sourcing.
                  Our ingredients are responsibly harvested and processed to preserve their natural integrity and potency.
                  By supporting local communities and adhering to environmentally friendly practices,
                  we strive to make a positive impact on both health and planet.
                </p>
              </div>
            </Col>
            <Col lg={9} md={8} className="p-4">
              <div className="sec-title text-center mb-3">
                <h2 className="sec-head-text">
                  Customer Satisfaction
                </h2>
                <p className="sec-sub-text">
                  At Wincenzo Herbal Cure, customer satisfaction is paramount.
                  We value transparency and integrity in all our interactions,
                  striving to build enduring relationships based on trust.
                  Our customer support team is dedicated to assisting you on your wellness journey,
                  ensuring your experience with our products exceeds expectations.
                </p>
              </div>
            </Col>
            <Col lg={9} md={8} className="p-4">
              <div className="sec-title text-center mb-3">
                <h2 className="sec-head-text">
                  Join Us on the Journey to Wellness
                </h2>
                <p className="sec-sub-text">
                  Whether you are seeking natural remedies to enhance your
                  well-being or exploring the profound benefits of Ayurveda,
                  Wincenzo Herbal Cure invites you to embark on a journey of health and harmony.
                  Discover the power of nature’s wisdom, crafted with care for your holistic wellness.

                  Experience the purity of Ayurveda with Wincenzo Herbal Cure –
                  where tradition, quality, and innovation converge for your well-being.

                </p>
              </div>
            </Col>
          </div>
        </div>
      </div>
    </>
  );
}
export default Singleabout;






