import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPolicy } from '../../features/cms/pageSlice';

export default function Privacy() {
  const dispatch = useDispatch();
  const { PrivacyPolicy, loading, error } = useSelector((state) => state.page);

  useEffect(() => {
    dispatch(getPolicy());
  }, [dispatch]);

  return (
    <section className="privacy">
      <div className="container">
        <div className="row justify-content-center mb-3">
          <div className="col-lg-6 text-center">
            <h3 className="product-tab-title mb-1">Privacy Policy</h3>
            <div className="bar"></div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            {loading && <p>Loading...</p>}
            {error && <p className="text-danger">Error: {error}</p>}
            {!loading && !error && (
              <div
                dangerouslySetInnerHTML={{ __html: PrivacyPolicy?.description || 'No privacy policy found.' }}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  );
}














// import React, { useEffect } from "react";
// import { Col, Container, Row } from "react-bootstrap";
// import { getPolicy } from "../../features/cms/pageSlice";
// import { useDispatch, useSelector } from "react-redux";

// export default function Privacy() {
//   const dispatch = useDispatch();
//   const { PrivacyPolicy } = useSelector((state) => state.page);
//   useEffect(() => {
//     dispatch(getPolicy());
//   }, [dispatch]);



//   return (
//     <>
//       <section className="privacy">
//         <Container>
//           <Row className="justify-content-center mb-3">
//             <Col lg={6} className="text-center">
//               <h3 className="product-tab-title mb-1">Privacy Policy</h3>
//               <div className="bar"></div>
//             </Col>
//           </Row>
//           <Row>
//             <Col>
//               <p>{PrivacyPolicy?.description}</p>
//             </Col>
//           </Row>
//         </Container>
//       </section>
//     </>
//   );
// }
