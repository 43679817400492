// const getTokenFromLocalStorage = localStorage.getItem("TOKEN")
//   ? JSON.parse(localStorage.getItem("TOKEN"))
//   : null;

// export const config = {
//   headers: {
//     Authorization: `Bearer ${
//       getTokenFromLocalStorage !== null && getTokenFromLocalStorage
//         ? getTokenFromLocalStorage
//         : ""
//     }`,
//     Accept: "application/json",
//   },
// };

// Define a function to retrieve the token
const getTokenFromLocalStorage = () => {
  const token = localStorage.getItem("TOKEN");
  return token ? JSON.parse(token) : "";
};

// Export config with a dynamic token
export const getConfig = () => ({
  headers: {
    Authorization: `Bearer ${getTokenFromLocalStorage()}`,
    Accept: "application/json",
  },
});
