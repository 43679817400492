import React from 'react'

import Form from './Contact/Form'


const Contact = () => {


  return (
    <div>
      <Form />
    </div>
  )
}

export default Contact
